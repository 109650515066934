import React from 'react'
import { Typography, MenuItem, Box } from '@material-ui/core'
import classes from './IUIAsyncMultiSelect.module.scss'
import clsx from 'clsx'

export default function MenuItemsComponent(props) {
  return (
    <MenuItem
      value={props?.menuData?.categoryValue}
      id={`async-select-menu-item-${props?.menuData?.selectPropsId}-seleted-value-${props?.menuData?.categoryValue}`}
      className={clsx(
        props?.menuData?.classForMenuItem,
        classes.menuItemContainer,
        ' iui__p-5 iui__d-flex iui__align-items-flexStart',
        classes.menuItem,
        {
          [classes.isDisabledMain]: props?.menuData?.categoryData?.isDisabled
        }
      )}
      onClick={() =>
        props?.menuData?.selectOption(props?.menuData?.categoryData)
      }
    >
      {props?.menuData?.iconForLabel && (
        <Box
          className={clsx(
            classes.menuItemIcon,
            'iui__d-flex iui__align-items-center iui__mt-1'
          )}
        >
          {props.menuData.iconForLabel}
        </Box>
      )}
      <Box className={clsx('iui__pl-2')}>
        <Typography
          variant='inherit'
          className={clsx(
            props?.menuData?.categoryData?.isDisabled &&
              'iui__font--color-black400'
          )}
        >
          {props?.menuData?.label}
        </Typography>
        {props?.menuData?.subLabelValue !== '' &&
          props?.menuData?.subLabelStateValue !== '' && (
            <Box
              className={clsx(
                classes.subLabelVal,
                props?.menuData?.categoryData?.isDisabled &&
                  'iui__font--color-black400'
              )}
            >
              {props?.menuData?.subLabelStateValue},{' '}
              {props?.menuData?.subLabelValue}
            </Box>
          )}
        {props?.menuData?.categoryData?.isDisabled && (
          <Box
            className={clsx(
              classes.isDisabledText,
              'iui__font--color-black500'
            )}
          >
            A location rule already exists for this location
          </Box>
        )}
      </Box>
    </MenuItem>
  )
}
