import { Box, OutlinedInput, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { IUILabel } from '../IUILabel'
import classes from './IUIInput.module.scss'
import { checkEmptyObject } from '../../utils/_helpers'
import Tooltip from '@material-ui/core/Tooltip'
// import PropTypes from 'prop-types'

export const IUIInput = (props) => {
  const {
    label = null,
    secondaryLabel = null,
    description = null,
    defaultValue = '',
    placeholder = null,
    required = false,
    fullWidth = false,
    isError = false,
    type = 'text',
    onValueChange = () => {}, // param: { _name, _value, _isError, _errorMsg, _obj, _event }
    readOnly = false,
    hasInfoIcon = false,
    headerType = false,
    name = '',
    id = '',
    className = '',
    validation = {},
    errorMsg = '',
    tooltipText = '',
    multiline = false,
    multiRow = 4,
    disableText = '',
    multilinefw = false,
    style = {}
  } = props

  const [value, setValue] = useState(defaultValue)

  const inputOnChange = (e) => {
    const _value = validation.isNumber ? Number(e.target.value) : e.target.value
    if (String(_value).trim() === '') {
      setValue('')
    } else {
      if (!checkEmptyObject(validation) && validation.regexOnChange) {
        if (!validation.regexOnChange.test(_value)) return false

        if (
          validation.maxLength &&
          String(_value).length > validation.maxLength
        )
          return false
      }
      setValue(_value)
    }
  }
  const inputFocusOut = (e) => {
    let _isError = false
    let _errorMsg = ''
    const _value = validation.isNumber ? Number(e.target.value) : e.target.value
    if (required) {
      if (_value.trim() === '') {
        _isError = true
        _errorMsg = 'Value cannot be empty'
      } else {
        if (!checkEmptyObject(validation) && validation.regexOnBlur) {
          if (
            typeof validation.regexOnBlur === 'object' &&
            validation.regexOnBlur.length
          ) {
            for (let i = 0; i < validation.regexOnBlur.length; i++) {
              if (!validation.regexOnBlur[i].regex.test(_value)) {
                _isError = true
                _errorMsg = validation.regexOnBlur[i].message
                break
              }
            }
          } else {
            if (!validation.regexOnBlur.test(_value)) {
              _isError = true
              _errorMsg = 'Invalid input'
            }
          }
        }
      }
    } else {
      if (_value.trim() !== '') {
        if (validation.regexOnBlur) {
          if (
            typeof validation.regexOnBlur === 'object' &&
            validation.regexOnBlur.length
          ) {
            for (let i = 0; i < validation.regexOnBlur.length; i++) {
              if (!validation.regexOnBlur[i].regex.test(_value)) {
                _isError = true
                _errorMsg = validation.regexOnBlur[i].message
                break
              }
            }
          } else {
            if (!validation.regexOnBlur.test(_value)) {
              _isError = true
              _errorMsg = 'Invalid input'
            }
          }
        }
      }
    }

    if (typeof onValueChange === 'function') {
      const param = {
        _name: name,
        _value: value,
        _isError: _isError,
        _errorMsg: _errorMsg,
        _obj: null,
        _event: null
      }
      onValueChange(param)
    }
  }
  useEffect(() => {
    // update local state whenever root lvl state get changed
    setValue(defaultValue)
  }, [defaultValue])

  return (
    <Box>
      <IUILabel
        label={label}
        secondaryLabel={secondaryLabel}
        description={description}
        required={required}
        hasInfoIcon={hasInfoIcon}
        headerType={headerType}
        tooltipText={tooltipText}
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Tooltip
          placement='top-start'
          disableHoverListener={!readOnly}
          disableFocusListener={!readOnly}
          disableTouchListener={!readOnly}
          title={disableText}
        >
          <OutlinedInput
            id={id}
            value={value}
            required={required}
            placeholder={placeholder}
            className={clsx(
              classes.inputStyle,
              classes.inputSelectorCls,
              classes.marginVal,
              className,
              { [classes.fullWidth]: fullWidth },
              { [classes.inputBorder]: !isError },
              { [classes.errorBorder]: isError },
              { [classes.readOnly]: readOnly },
              { [classes.multilinefw]: multilinefw }
            )}
            // error={isError}
            type={type}
            onChange={inputOnChange}
            onBlur={inputFocusOut}
            // inputProps={{...inputProps}}
            readOnly={readOnly}
            name={name}
            multiline={multiline}
            rows={multiRow}
            style={{ ...style }}
          />
        </Tooltip>

        {isError && (
          <Typography
            variant='body2'
            component='span'
            className={clsx(classes.errorTextCls, {
              [classes.fullWidth]: fullWidth
            })}
          >
            {errorMsg}
          </Typography>
        )}
      </div>
    </Box>
  )
}

// IUIInput.propTypes = {
// 	/**
// 	 * Add custom class to input field
// 	 */
// 	className: PropTypes.string,
// 	/**
// 	 * Add predefined value if required
// 	 */
// 	defaultValue: PropTypes.string,
// 	/**
// 	 * we can provide additional text below label
// 	 */
// 	description: PropTypes.string,
// 	/**
// 	 * When input field is disable then tolltip text to show on hover
// 	 */
// 	disableText: PropTypes.string,
// 	/**
// 	 * Error message if any for input field
// 	 */
// 	errorMsg: PropTypes.string,
// 	/**
// 	 * If input field should be fullwidth of the container its wrapped into
// 	 */
// 	fullWidth: PropTypes.bool,
// 	/**
// 	 * if label should have an info icon or not
// 	 */
// 	hasInfoIcon: PropTypes.bool,
// 	/**
// 	 * If label is header type styled(bold and black)
// 	 */
// 	headerType: PropTypes.bool,
// 	/**
// 	 * assign ID to input fields
// 	 */
// 	id: PropTypes.string,
// 	/**
// 	 * If error is present in input field
// 	 */
// 	isError: PropTypes.bool,
// 	/**
// 	 * if a label is required above input field and the value of it
// 	 */
// 	label: PropTypes.string,
// 	/**
// 	 * Defines the number of grids the component is going to use.
// 	 * It's applied for the `lg` breakpoint and wider screens if not overridden.
// 	 */
// 	lg: PropTypes.oneOf([
// 	  false,
// 	  'auto',
// 	  true,
// 	  1,
// 	  2,
// 	  3,
// 	  4,
// 	  5,
// 	  6,
// 	  7,
// 	  8,
// 	  9,
// 	  10,
// 	  11,
// 	  12
// 	]),
// 	/**
// 	 * Defines the number of grids the component is going to use.
// 	 * It's applied for the `md` breakpoint and wider screens if not overridden.
// 	 */
// 	md: PropTypes.oneOf([
// 	  false,
// 	  'auto',
// 	  true,
// 	  1,
// 	  2,
// 	  3,
// 	  4,
// 	  5,
// 	  6,
// 	  7,
// 	  8,
// 	  9,
// 	  10,
// 	  11,
// 	  12
// 	]),
// 	/**
// 	 * Defines the number of grids the component is going to use.
// 	 * It's applied for the `sm` breakpoint and wider screens if not overridden.
// 	 */
// 	sm: PropTypes.oneOf([
// 	  false,
// 	  'auto',
// 	  true,
// 	  1,
// 	  2,
// 	  3,
// 	  4,
// 	  5,
// 	  6,
// 	  7,
// 	  8,
// 	  9,
// 	  10,
// 	  11,
// 	  12
// 	]),
// 	/**
// 	 * Defines the space between the type `item` component.
// 	 * It can only be used on a type `container` component.
// 	 */
// 	spacing: PropTypes.oneOf(SPACINGS),
// 	/**
// 	 * Defines the `flex-wrap` style property.
// 	 * It's applied for all screen sizes.
// 	 */
// 	wrap: PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse']),
// 	/**
// 	 * Defines the number of grids the component is going to use.
// 	 * It's applied for the `xl` breakpoint and wider screens.
// 	 */
// 	xl: PropTypes.oneOf([
// 	  false,
// 	  'auto',
// 	  true,
// 	  1,
// 	  2,
// 	  3,
// 	  4,
// 	  5,
// 	  6,
// 	  7,
// 	  8,
// 	  9,
// 	  10,
// 	  11,
// 	  12
// 	]),
// 	/**
// 	 * Defines the number of grids the component is going to use.
// 	 * It's applied for all the screen sizes with the lowest priority.
// 	 */
// 	xs: PropTypes.oneOf([
// 	  false,
// 	  'auto',
// 	  true,
// 	  1,
// 	  2,
// 	  3,
// 	  4,
// 	  5,
// 	  6,
// 	  7,
// 	  8,
// 	  9,
// 	  10,
// 	  11,
// 	  12
// 	]),
// 	/**
// 	 * If `true`, it sets `min-width: 0` on the item.
// 	 * Refer to the limitations section of the documentation to better understand the use case.
// 	 */
// 	zeroMinWidth: PropTypes.bool
//   }
