import React from 'react'

export function Report(props) {
  const {
    color = '#1F2937',
    secondaryColor = '#F97316',
    width = '49',
    height = '49'
  } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 49 49'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M29.5065 42.0479H14.8677C11.554 42.0479 8.86768 39.3616 8.86768 36.0479V13.8008C8.86768 10.4871 11.554 7.80078 14.8677 7.80078H30.289C33.6027 7.80078 36.289 10.4871 36.289 13.8008V28.341'
        stroke={color}
      />
      <rect
        x='16.7299'
        y='23.3506'
        width='2'
        height='2.99071'
        rx='1'
        fill={secondaryColor}
      />
      <rect
        x='19.9011'
        y='21.1162'
        width='2'
        height='5.22539'
        rx='1'
        fill={secondaryColor}
      />
      <rect
        x='23.2889'
        y='17.8086'
        width='2'
        height='8.5328'
        rx='1'
        fill={secondaryColor}
      />
      <rect
        x='26.6784'
        y='15.6631'
        width='2'
        height='10.6781'
        rx='1'
        fill={secondaryColor}
      />
      <circle
        cx='29.5061'
        cy='33.3538'
        r='4.89681'
        stroke={color}
        strokeWidth='2'
      />
      <path
        d='M34.4204 36.3018L40.0715 41.9529C40.4786 42.36 40.4786 43.02 40.0715 43.4271V43.4271C39.6644 43.8342 39.0044 43.8342 38.5973 43.4271L32.9462 37.776'
        stroke={color}
        strokeWidth='2'
      />
    </svg>
  )
}
