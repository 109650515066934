import { CircularProgress } from '@material-ui/core'
import React from 'react'

export const IUICircularProgress = (props) => {
  const {
    size = 40,
    thickness = 3.6,
    value = 0,
    variant = 'indeterminate',
    className = '',
    style = {}
  } = props
  return (
    <CircularProgress
      color='inherit'
      size={size}
      thickness={thickness}
      value={value}
      variant={variant}
      className={className}
      style={{ ...style }}
    />
  )
}
