import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core'
import React, { Fragment } from 'react'
import clsx from 'clsx'
import { PropTypes } from 'prop-types'
import classes from './IUIAccordionMain.module.scss'

export const IUIAccordionMain = (props) => {
  const {
    expanded = false,
    onChange = () => {},
    panelId = null,
    accordionClass = '',
    summaryClass = '',
    detailsClass = '',
    actionClass = '',
    expandIcon = '',
    accordionStyle = {},
    summaryStyle = {},
    detailsStyle = {},
    actionStyle = {},
    summaryComponent = null,
    detailsComponent = null,
    actionComponent = null
  } = props

  return (
    <Fragment>
      <Accordion
        expanded={expanded === panelId}
        onChange={onChange}
        className={clsx(accordionClass, classes.iuiAccordion)}
        style={{ ...accordionStyle }}
      >
        <AccordionSummary
          expandIcon={expandIcon}
          className={clsx(summaryClass, classes.iuiAccordionSummary)}
          style={{ ...summaryStyle }}
        >
          {summaryComponent}
        </AccordionSummary>
        <AccordionDetails
          classes={{ root: clsx(classes.iuiAccordionDetails, detailsClass) }}
          style={{ ...detailsStyle }}
        >
          {detailsComponent}
        </AccordionDetails>
        {actionComponent && (
          <AccordionActions
            className={clsx(actionClass, classes.iuiAccordionActions)}
            style={{ ...actionStyle }}
          >
            {actionComponent}
          </AccordionActions>
        )}
      </Accordion>
    </Fragment>
  )
}

IUIAccordionMain.propTypes = {
  panelId: PropTypes.number,
  summaryComponent: PropTypes.element,
  actionComponent: PropTypes.element,
  detailsComponent: PropTypes.element,
  accordionStyle: PropTypes.object,
  detailsStyle: PropTypes.object,
  actionStyle: PropTypes.object,
  summaryStyle: PropTypes.object,
  accordionClass: PropTypes.string,
  summaryClass: PropTypes.string,
  detailsClass: PropTypes.string,
  actionClass: PropTypes.string
}
