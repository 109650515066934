import React from 'react'

export const Pencil = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      fill='none'
      viewBox='0 0 14 14'
    >
      <path
        fill={props.color}
        d='M8.586 1a2 2 0 012.828 0L13 2.586a2 2 0 010 2.829L4.854 13.56a1.5 1.5 0 01-1.061.44H1.5A1.5 1.5 0 010 12.5v-2.294c0-.397.158-.779.44-1.06L8.585 1zm-.172 3L10 5.586 11.586 4 10 2.415 8.414 4zm.172 3L7 5.415l-5 5V12h1.586l5-5z'
      />
    </svg>
  )
}
