import React from 'react'

export const ExpandLess = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      fill='none'
      viewBox='0 0 10 6'
    >
      <path
        fill='#6B7280'
        d='M.293.293a1 1 0 011.414 0L5 3.586 8.293.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
      />
    </svg>
  )
}
