import { Avatar, Box, Typography } from '@material-ui/core'
import React from 'react'
import styles from './IUIProfileBox.module.scss'
import clsx from 'clsx'

const getFirstLetterofName = (firstName, lastName) => {
  return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()
}

export const IUIProfileBox = (props) => {
  const {
    isLetterAvatar = true,
    firstName = '',
    lastName = '',
    profilePath = '',
    primaryText = '',
    secondaryText = '',
    primaryTextClassName = '',
    secondaryTextClassName = '',
    avatarClassName = '',
    alt = '',
    letterAvatarTextColor = '#EC5D25',
    letterAvatarBackgroundColor = '#FFF7ED',
    labelId = '',
    textBoxWidth = 200
  } = props
  return (
    <Box
      component='div'
      display='flex'
      alignItems='center'
      className={styles.child_element_gap}
    >
      <Box component='div' width={50}>
        {isLetterAvatar ? (
          <Avatar
            className={clsx(avatarClassName, styles.avatarLetter)}
            style={{
              backgroundColor: letterAvatarBackgroundColor,
              color: letterAvatarTextColor
            }}
          >
            {getFirstLetterofName(firstName, lastName)}
          </Avatar>
        ) : (
          <Avatar
            src={profilePath}
            alt={alt}
            className={clsx(avatarClassName)}
          />
        )}
      </Box>
      <Box
        component='div'
        display='flex'
        flexDirection='column'
        width={textBoxWidth}
      >
        <Typography
          className={clsx(
            styles.rowText,
            styles.textTransform,
            primaryTextClassName
          )}
          id={labelId}
        >
          {primaryText}
        </Typography>
        <Typography
          className={clsx(
            styles.rowText,
            styles.lightColor,
            secondaryTextClassName
          )}
          id={labelId}
        >
          {secondaryText}
        </Typography>
      </Box>
    </Box>
  )
}
