import React from 'react'
import Expense from './Expense/Expense'
import Travel from './Travel/Travel'
import { ContactProductSpecialist } from './GettingStartedSvg'
import styles from './GettingStartedWith.module.scss'
import clsx from 'clsx'
import { IUIBox, IUITypography } from '@itilite/iuistyles'

const GettingStartedWith = (props) => {
  const { first_name, expense, travel } = props

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const viewHandler = () => {
    if (travel === 0 && expense === 1) {
      return Expense(props)
    } else if (travel === 1 && expense === 0) {
      return Travel(props)
    } else if (travel === 1 && expense === 1) {
      return (
        <IUIBox
          component='div' className={styles.travelPlusExpenseContainer}>
          {Travel(props)}
          {Expense(props)}
        </IUIBox>
      )
    }
  }

  const welcomAndSupportView = () => {
    return (
      <IUIBox
        component='div' className={styles.welcomeAndSupportContainer}>
        <IUIBox
          component='div' className={styles.welcomeContainer}>
          <h3 className={clsx(styles.helloHeading, styles["text--size__xxl"])}>
            Welcome to ITILITE
            {first_name !== '' ? `, ${capitalizeFirstLetter(first_name)}` : ''}
          </h3>
        </IUIBox>
        <IUIBox
          component='div' className={styles.supportContainer}>
          <IUIBox
            component='div' className={styles.supportSubContainer}>
            <IUIBox
              component='div' className={styles.contentContainer}>
              <IUIBox
                component='div' className={styles.iconContainer}>
                <IUIBox
                  component='div' className={clsx(styles.image__wrapper, styles.borderradius__circle)}>
                  <ContactProductSpecialist />
                </IUIBox>
              </IUIBox>
              <IUIBox
                component='div' className={styles.detailsContainer}>
                <IUITypography className={clsx(styles['text--color__primary'], styles['text--weight__medium'])}>
                  {'Set up a '}
                  <span className={styles['text--weight__bold']}>free 60 min</span>
                  {' consultation session for account setup and best practices'}
                </IUITypography>
                <a
                  href='https://calendly.com/pallavi-rao/itilite-platform-walkthrough'
                  target='_blank'
                  rel='noopener noreferrer'
                  className={styles.contact_product}
                  // className={clsx(styles['text--color__theme'], styles['text--weight__semibold'], styles['text--family__theme'])}
                >
                  Contact Product Specialist
                </a>
              </IUIBox>
            </IUIBox>
          </IUIBox>
        </IUIBox>
      </IUIBox>
    )
  }

  return (
    <IUIBox
      component='div' className={styles.gs__container}>
      {welcomAndSupportView()}
      {viewHandler()}
    </IUIBox>
  )
}

export default GettingStartedWith
