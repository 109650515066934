import React from 'react'

export const Progress = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill='#D97706'
        d='M8 1C4.136 1 1 4.136 1 8s3.136 7 7 7 7-3.136 7-7-3.136-7-7-7zm1 3.333v3.253l1.707 1.707a1 1 0 01-1.414 1.414l-2-2A1 1 0 017 8V4.333a1 1 0 012 0z'
      />
    </svg>
  )
}
