import { Typography, FormControlLabel, RadioGroup } from '@material-ui/core'
import React, { Fragment } from 'react'
import clsx from 'clsx'
import { IUIPrimaryRadio } from '../IUIPrimaryRadio'
import { IUIDivider } from '../IUIDivider'
import { IUIBox } from '../IUIBox'
import { PropTypes } from 'prop-types'

export const IUIRadioGroup = ({
  options = [],
  name = '',
  value = '',
  disabled = false,
  cardLayout = false,
  labelClass = '',
  handleChange = () => {},
  labelTag = null
}) => {
  return (
    <IUIBox
      component='div'
      className={clsx(
        cardLayout && 'iui__border--solid_black300-1 iui__borderRadius-6'
      )}
    >
      {options.length > 0 && (
        <RadioGroup name={name} value={value} onChange={handleChange}>
          {options.map((_c, _i) => {
            return (
              <Fragment key={_i}>
                <IUIBox
                  className={clsx(cardLayout ? 'iui__p-10' : 'iui__pb-5')}
                >
                  <FormControlLabel
                    key={_i}
                    value={_c.value}
                    disabled={disabled}
                    control={<IUIPrimaryRadio disableRipple />}
                    label={
                      <Typography variant='body1' className={clsx(labelClass)}>
                        {_c.label} {_c.labelTag}
                      </Typography>
                    }
                  />

                  {_c.actions && <Fragment>{_c.actions}</Fragment>}
                </IUIBox>
                {cardLayout && options.length - 1 !== _i && (
                  <IUIDivider
                    variant='fullWidth'
                    className={clsx('iui__bg-black300')}
                  />
                )}
              </Fragment>
            )
          })}
        </RadioGroup>
      )}
    </IUIBox>
  )
}

IUIRadioGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  cardLayout: PropTypes.bool,
  labelClass: PropTypes.string,
  handleChange: PropTypes.func.isRequired
}
