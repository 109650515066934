import React from 'react'

import { Box } from '@material-ui/core'
import clsx from 'clsx'

export const IUIBox = (props) => {
  const { className = '', gap = false, padding = false, style = {} } = props

  return (
    <Box
      className={clsx(className)}
      style={{
        gap: gap && gap,
        padding: padding && padding,
        ...style
      }}
      {...props}
    >
      {props.children}
    </Box>
  )
}
