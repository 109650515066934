import * as React from 'react'
import classes from './IUIModal.module.scss'
import { CrossIcon } from '../../utils/icons/CrossIcon'
import { IUIButton } from '../IUIButton'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import clsx from 'clsx'
import { PropTypes } from 'prop-types'
import { Box, IconButton, makeStyles } from '@material-ui/core'
import { IUITypography } from '../IUITypography'
import { IUIBox } from '../IUIBox'
import { Info } from '../../icons/svg'
import { IUITooltip } from '../IUITooltip'

export const IUIModal = (props) => {
  const {
    isOpen = false,
    closeHandler = () => {},
    modalClass = '',
    closeBtnClass = '',
    headerText = '',
    subHanderText = '',
    cancelLabel = '',
    submitLabel = '',
    customButtonLabel = '',
    customButtonClass = '',
    customButtonHandler = () => {},
    submitHandler = () => {},
    modalBodyStyle = {},
    footerStyle = {},
    hideCloseIcon = false,
    width = '444px',
    isDisabledSubmitBtn = false,
    showGotitButton = false,
    submitBtnClass = '',
    showInfoIcon = false,
    infoText = '',
    toolStyle = {}
  } = props

  const useStyles = makeStyles({
    paper: {
      '& .MuiDialog-container .MuiDialog-paper': {
        width: width,
        maxWidth: width,
        overflowY: 'visible'
      }
    }
  })
  const localClasses = useStyles()
  return (
    <React.Fragment>
      <Box>
        <Dialog
          maxWidth={false}
          aria-labelledby='customized-dialog-title'
          open={isOpen}
          style={{ borderRadius: '12px' }}
          className={clsx(
            classes.modalContainer_new,
            localClasses.paper,
            modalClass
          )}
          onClose={closeHandler}
        >
          {(headerText || subHanderText || !hideCloseIcon) && (
            <IUIBox className={classes.headerContainer}>
              <IUIBox className={classes.headerTextContainer}>
                {headerText !== '' && (
                  <MuiDialogTitle className={classes.headerText}>
                    {headerText}
                  </MuiDialogTitle>
                )}
                {subHanderText && (
                  <IUIBox className={classes.subHeading}>
                    <IUITypography>{subHanderText}</IUITypography>
                    {showInfoIcon && infoText && (
                      <IUITooltip tooltipText={infoText} toolStyle={toolStyle}>
                        <Box className={classes.infoIcon}>
                          <Info width={14} height={14} />
                        </Box>
                      </IUITooltip>
                    )}
                  </IUIBox>
                )}
              </IUIBox>
              {closeHandler ? (
                <IconButton
                  className={clsx(
                    classes.btn_new,
                    closeBtnClass,
                    {
                      [classes.hideCloseIcon]: hideCloseIcon
                    },
                    headerText === '' &&
                      subHanderText === '' &&
                      classes.closeBtnOnly
                  )}
                  onClick={closeHandler}
                  aria-label='close'
                  disableRipple
                >
                  <CrossIcon width='14px' height='14px' />
                </IconButton>
              ) : null}
            </IUIBox>
          )}
          <MuiDialogContent style={modalBodyStyle} className='iui__scrollbar'>
            {props.children}
          </MuiDialogContent>
          <MuiDialogActions
            className={`${classes.footerContainer} ${
              customButtonLabel && classes.customFooter
            }`}
            style={footerStyle}
          >
            {customButtonLabel !== '' && (
              <Box className={classes.customButtonContainer}>
                <IUIButton
                  onClick={customButtonHandler}
                  buttonText={customButtonLabel}
                  className={customButtonClass}
                  type='outlined'
                />
              </Box>
            )}
            <Box
              className={showGotitButton ? classes.alertFooter : classes.footer}
            >
              {/* {showMoveButton && (
                <CustomButton
                  onClickHandler={openMoveHandler}
                  buttonText='Replace/Move Users'
                  type='outlined'
                />
              )} */}
              {cancelLabel !== '' && (
                <IUIButton
                  onClick={closeHandler}
                  buttonText={cancelLabel}
                  type='outlined'
                  className={showGotitButton && classes.buttonWidth}
                />
              )}
              {submitLabel !== '' && (
                <IUIButton
                  onClick={submitHandler}
                  buttonText={submitLabel}
                  type='contained'
                  disabled={isDisabledSubmitBtn}
                  className={clsx(
                    submitLabel === 'Delete' && classes.redButton,
                    submitBtnClass && submitBtnClass
                  )}
                />
              )}
            </Box>
          </MuiDialogActions>
        </Dialog>
      </Box>
    </React.Fragment>
  )
}

IUIModal.propTypes = {
  isOpen: PropTypes.bool,
  closeHandler: PropTypes.func,
  headerText: PropTypes.string,
  subHanderText: PropTypes.string,
  cancelLabel: PropTypes.string,
  submitLabel: PropTypes.string,
  customButtonLabel: PropTypes.string,
  customButtonClass: PropTypes.string,
  customButtonHandler: PropTypes.func,
  submitHandler: PropTypes.func,
  modalBodyStyle: PropTypes.object,
  footerStyle: PropTypes.object,
  hideCloseIcon: PropTypes.bool,
  width: PropTypes.string,
  isDisabledSubmitBtn: PropTypes.bool,
  showGotitButton: PropTypes.bool,
  showInfoIcon: PropTypes.bool,
  infoText: PropTypes.string,
  closeBtnClass: PropTypes.string,
  modalClass: PropTypes.string
}
