import { Backdrop } from '@material-ui/core'
import React from 'react'
import styles from './IUIBackdrop.module.scss'
import clsx from 'clsx'

export const IUIBackdrop = (props) => {
  const { open = false, onClick = () => {}, className = '', style = {} } = props
  return (
    <Backdrop
      open={open}
      style={{ ...style }}
      className={clsx(styles.backdrop, className)}
      onClick={onClick}
    >
      {props.children}
    </Backdrop>
  )
}
