import React from 'react'
import clsx from 'clsx'
import { InputLabel } from '@material-ui/core'
import classes from './IUILabel.module.scss'
import { IUITooltip } from '../IUITooltip'
import { IUITypography } from '../IUITypography'
import { IUIBox } from '../IUIBox'

const infoSvg = (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8 13.9995C4.68629 13.9995 2 11.3132 2 7.99951C2 4.6858 4.68629 1.99951 8 1.99951C11.3137 1.99951 14 4.6858 14 7.99951C14 11.3132 11.3137 13.9995 8 13.9995ZM8 14.9995C11.866 14.9995 15 11.8655 15 7.99951C15 4.13352 11.866 0.999512 8 0.999512C4.13401 0.999512 1 4.13352 1 7.99951C1 11.8655 4.13401 14.9995 8 14.9995Z'
      fill='#6B7280'
    />
    <path
      d='M8.5 10.4995V5.99951H6.5V6.99951H7.5V10.4995H6V11.4995H10V10.4995H8.5Z'
      fill='#6B7280'
    />
    <path
      d='M8 3.49951C7.58579 3.49951 7.25 3.8353 7.25 4.24951C7.25 4.66373 7.58579 4.99951 8 4.99951C8.41421 4.99951 8.75 4.66373 8.75 4.24951C8.75 3.8353 8.41421 3.49951 8 3.49951Z'
      fill='#6B7280'
    />
  </svg>
)
export const IUILabel = (props) => {
  const {
    label = null,
    secondaryLabel = null,
    headerType = null,
    description = null,
    required = false,
    hasInfoIcon = false,
    style = {},
    tooltipText = '',
    className = {}
  } = props
  return (
    <React.Fragment>
      {label && (
        <IUIBox className={classes.mainLabelCls}>
          <InputLabel
            className={clsx(
              classes.labelIcon,
              classes.astreikColor,
              className,
              {
                [classes.headerLabelFontCls]: headerType,
                [classes.elementLabelFontCls]: !headerType
              }
            )}
            required={required}
            style={style}
          >
            {label}
            {secondaryLabel ? (
              <InputLabel style={{ display: 'inline' }} color='secondary'>
                {' '}
                ( {secondaryLabel} )
              </InputLabel>
            ) : null}
          </InputLabel>
          {hasInfoIcon && (
            <IUITooltip tooltipText={tooltipText} placement='top-end'>
              {infoSvg}
            </IUITooltip>
          )}
        </IUIBox>
      )}
      {description && (
        <IUITypography
          variant='body2'
          component='p'
          className={classes.subLabelCls}
        >
          {description}
        </IUITypography>
      )}
    </React.Fragment>
  )
}
