import React from 'react'
import Button from '@material-ui/core/Button'
import clsx from 'clsx'
import classes from './IUIButton.module.scss'
import { Box } from '@material-ui/core'

export const IUIButton = (props) => {
  const {
    buttonText = '',
    startIcon = null,
    endIcon = null,
    disableRipple = true,
    type = 'text',
    onClick = () => {},
    className = '',
    disabled = false,
    target = '_blank',
    href = '',
    isLink = false,
    count = '',
    style = {}
  } = props

  return (
    <React.Fragment>
      {type === 'text' && (
        <Button
          target={isLink ? target.toString() : undefined}
          href={isLink ? href.toString() : undefined}
          startIcon={startIcon && startIcon}
          endIcon={endIcon && endIcon}
          className={clsx(classes.textBtn, classes.muiBtn, className)}
          disableRipple={disableRipple}
          onClick={onClick}
          disabled={disabled}
          style={{ ...style }}
        >
          {buttonText}
        </Button>
      )}
      {type === 'outlined' && (
        <Button
          startIcon={startIcon && startIcon}
          endIcon={endIcon && endIcon}
          className={clsx(classes.muiBtn, classes.outlinedBtn, className)}
          disableRipple={disableRipple}
          variant={type}
          onClick={onClick}
          disabled={disabled}
          style={{ ...style }}
        >
          {buttonText}
        </Button>
      )}
      {type === 'contained' && (
        <Button
          startIcon={startIcon && startIcon}
          endIcon={endIcon && endIcon}
          className={clsx(classes.containedBtn, classes.muiBtn, className)}
          disableRipple={disableRipple}
          variant={type}
          onClick={onClick}
          disabled={disabled}
          style={{ ...style }}
        >
          {buttonText}
        </Button>
      )}
      {type === 'customContained' && (
        <Button
          startIcon={startIcon && startIcon}
          endIcon={endIcon && endIcon}
          className={clsx(classes.containedBtn, classes.muiBtn, className)}
          disableRipple={disableRipple}
          variant='contained'
          onClick={onClick}
          disabled={disabled}
          style={{ ...style }}
        >
          {buttonText}
          {count !== '' && (
            <Box component='div' className={classes.customMiniBox}>
              {count}
            </Box>
          )}
        </Button>
      )}
    </React.Fragment>
  )
}
