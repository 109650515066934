import React from 'react'

export function Info(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      fill='none'
      viewBox='0 0 14 15'
    >
      <path
        fill='#6B7280'
        d='M7 13.003a6 6 0 110-12 6 6 0 010 12zm0 1a7 7 0 100-14 7 7 0 000 14z'
      />
      <path
        fill='#6B7280'
        d='M7.5 9.503v-4.5h-2v1h1v3.5H5v1h4v-1H7.5zM7 2.503a.75.75 0 100 1.5.75.75 0 000-1.5z'
      />
    </svg>
  )
}
