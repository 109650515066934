import { Box, Typography } from '@material-ui/core'
import React from 'react'
import classes from './IUISideSheet.module.scss'
import clsx from 'clsx'
import { IUIButton } from '../IUIButton'
import { CrossIcon } from '../../utils/icons/CrossIcon'
import { IUISearch } from '../IUISearch'

export const IUISideSheet = (props) => {
  const {
    data,
    sideSheetTitle,
    placeholder,
    headerActionButton,
    headerActionButtonText,
    showSheet,
    closeHandler,
    actionButtonHandler,
    sideSheetSearchHandler,
    onKeyPressHandleSearch,
    searchValue,
    sideSheetScrollHandler,
    sideSheetId
  } = props

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom) {
      sideSheetScrollHandler()
    }
  }

  return (
    <Box id={sideSheetId}>
      <Box
        className={clsx(
          classes.sideSheetWrapper,
          showSheet && classes.sideSheetWrapperTransition
        )}
      >
        <Box
          className={clsx(
            classes.sideSheetHeader,
            'iui__p-15  iui__font--weight-medium iui__d-flex iui__justify-content-spaceBetween iui__align-items-center iui__border--bottom_solid_black200-1'
          )}
        >
          <Typography className='iui__font--weight-medium'>
            {sideSheetTitle}
          </Typography>
          {headerActionButton && (
            <IUIButton
              type='outlined'
              buttonText={headerActionButtonText}
              onClick={() => actionButtonHandler()}
              className='iui__ml-40'
            />
          )}
          <Box onClick={() => closeHandler()} className='iui__cursor-pointer '>
            <CrossIcon width='14px' height='14px' />
          </Box>
        </Box>
        <Box>
          <Box className='iui__my-10 iui__mx-15'>
            <IUISearch
              placeholder={placeholder}
              value={searchValue}
              onChangeHandler={(e) => sideSheetSearchHandler(e)}
              //   onKeyPressHandle={(e) => onKeyPressHandleSearch(e)}
            />
          </Box>
          <Box
            className={clsx(
              classes.sidesheetContentBox,
              'iui__my-10 iui__mx-15 iui__scrollbar'
            )}
            onScroll={handleScroll}
          >
            {data.map((item, idx) => (
              <Typography
                key={idx}
                className='iui__font--para-normal iui__font--color-black900 iui__my-10'
              >
                {item.name}
              </Typography>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
