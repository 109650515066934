//	IUIblack
export const IUIblack50 = '#f9fafb'
export const IUIblack100 = '#f3f4f6'
export const IUIblack200 = '#e5e7eb'
export const IUIblack300 = '#d1d5db'
export const IUIblack400 = '#9ca3af'
export const IUIblack500 = '#6b7280'
export const IUIblack600 = '#4b5563'
export const IUIblack700 = '#374151'
export const IUIblack800 = '#1f2937'
export const IUIblack900 = '#111827'

//	IUIgray
export const IUIgray100 = '#f7fafc'
export const IUIgray200 = '#edf2f7'
export const IUIgray300 = '#e2e8f0'
export const IUIgray400 = '#cbd5e0'
export const IUIgray500 = '#a0aec0'
export const IUIgray600 = '#718096'
export const IUIgray700 = '#4a5568'
export const IUIgray800 = '#2d3748'
export const IUIgray900 = '#1a202c'

//	IUIorange
export const IUIorange50 = '#fff7ed'
export const IUIorange100 = '#ffedd5'
export const IUIorange200 = '#fed7aa'
export const IUIorange300 = '#fdba74'
export const IUIorange400 = '#fb923c'
export const IUIorange500 = '#f97316'
export const IUIorange600 = '#ec5d25'
export const IUIorange700 = '#c2410c'
export const IUIorange800 = '#9a3412'
export const IUIorange900 = '#7c2d12'

export const IUIgreen50 = '#ECFDF5'
export const IUIgreen400 = '#34D399'
export const IUIgreen600 = '#059669'

export const IUIblack = '#000000'
export const IUIwhite = '#ffffff'

export const IUIsuccess = IUIgreen600
export const IUIthemeColor = IUIorange600

export const IUIlinkColor = '#2B6CB0'
