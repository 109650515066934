import React, { Fragment } from 'react'
import { Box, Typography } from '@material-ui/core'
import styles from './IUIAlert.module.scss'
import { ToastInfoIcon } from '../../utils/icons/ToastInfoIcon'
export const IUIAlert = (props) => {
  const {
    title = '',
    showDesc = false,
    description = '',
    icon = <ToastInfoIcon color='#6B7280' />
  } = props
  return (
    <Box className={styles.alertBox}>
      {icon}
      <div>
        <Typography className={styles.title}>{title}</Typography>
        {showDesc ? (
          <Typography className={styles.desc}>{description}</Typography>
        ) : (
          <></>
        )}
      </div>
    </Box>
  )
}
