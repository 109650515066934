import React from 'react'
import { Typography, MenuItem } from '@material-ui/core'
import classes from './IUIAsyncSelect.module.scss'
import clsx from 'clsx'
import { IUIProfileBox } from '../IUIProfileBox'
const DefaultMenuItem = ({
  option = {},
  selectProps = {},
  value = '',
  selectOption = () => {}
}) => {
  return (
    <MenuItem
      value={option.value}
      id={`async-select-menu-item-${selectProps.id}-seleted-value-${option.value}`}
      className={clsx(
        classes.popOverText,
        {
          [classes.lightBackground]: value !== option.value
        },
        {
          [classes.darkBackground]: value === option.value
        }
      )}
      onClick={() => selectOption(option)}
    >
      <Typography variant='inherit'>{option.label}</Typography>
    </MenuItem>
  )
}

const DetailedMenuItem = ({
  option = {},
  selectProps = {},
  value = '',
  selectOption = () => {}
}) => {
  return (
    <MenuItem
      value={option.value}
      id={`async-select-menu-item-${selectProps.id}-seleted-value-${option.value}`}
      className={clsx(
        classes.popOverText,
        {
          [classes.lightBackground]: value !== option.value
        },
        {
          [classes.darkBackground]: value === option.value
        }
      )}
      onClick={() => selectOption(option)}
    >
      <IUIProfileBox
        firstName={option.data.first_name}
        lastName={option.data.last_name}
        isLetterAvatar={!option.data.profile_pic}
        primaryText={option.data.first_name + ' ' + option.data.last_name}
        secondaryText={option.data.email}
        letterAvatarTextColor='black'
        letterAvatarBackgroundColor='#d3d3d3'
        profilePath={option.data?.profile_pic}
      />
    </MenuItem>
  )
}

const CustomMenuItem = (props) => {
  switch (props.option.type) {
    case 'detailed':
      return <DetailedMenuItem {...props} />

    default:
      return <DefaultMenuItem {...props} />
  }
}

export default CustomMenuItem
