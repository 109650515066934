import React from 'react'

export const PasswordEyeIconOpen = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.1717 7.30865C14.4877 7.72198 14.4877 8.27998 14.1717 8.69265C13.1763 9.99198 10.7883 12.6673 8.00033 12.6673C5.21233 12.6673 2.82433 9.99198 1.829 8.69265C1.67526 8.49474 1.5918 8.25126 1.5918 8.00065C1.5918 7.75004 1.67526 7.50656 1.829 7.30865C2.82433 6.00932 5.21233 3.33398 8.00033 3.33398C10.7883 3.33398 13.1763 6.00932 14.1717 7.30865V7.30865Z'
        stroke='#6B7280'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z'
        stroke='#6B7280'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
