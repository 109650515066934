import React, { useState, useEffect, useRef } from 'react'
import { AsyncPaginate, wrapMenuList } from 'react-select-async-paginate'
import { IUILabel } from '../IUILabel/index.js'
import classes from './IUIAsyncSelect.module.scss'
import clsx from 'clsx'
import { Typography, MenuItem } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import CustomMenuItem from './CustomMenuItem'
const CustomMenuList = (
  props,
  selectCTA,
  customLoadingText,
  customLoadingTextStyle
) => {
  const [value, setValue] = useState(null)
  const scrollableDiv = useRef(null)
  useEffect(() => {
    if (
      props?.getValue &&
      props.getValue().length &&
      props.getValue()[0].value !== value
    ) {
      setValue(props.getValue()[0].value)
    }
  }, [props])

  useEffect(() => {
    const el = document.getElementById(
      `async-select-menu-item-${props.selectProps.id}-seleted-value-${value}`
    )
    if (el) {
      scrollableDiv.current.scrollTop = el.offsetTop
    }
  }, [value])
  return (
    <div>
      <div
        className={classes.menuBox}
        onScroll={props.selectProps.handleScrolledToBottom}
        ref={scrollableDiv}
      >
        {props.options.length ? (
          props.options.map((option, _i) => {
            if (selectCTA) {
              // ? This is used for adding CTA at last of list
              if (option.value === selectCTA.value) {
                return (
                  <MenuItem
                    className={clsx(classes.lightBackground)}
                    onClick={(e) => selectCTA.callback(e, selectCTA.value)}
                    key={option.value}
                  >
                    {selectCTA.component}
                  </MenuItem>
                )
              }
            }
            return (
              <CustomMenuItem
                key={_i}
                option={option}
                selectProps={props.selectProps}
                selectOption={props.selectOption}
                value={value}
              />
            )
          })
        ) : (
          <MenuItem className={clsx(classes.dropdownLoader)}>
            <Typography style={customLoadingTextStyle}>
              {customLoadingText}
            </Typography>
          </MenuItem>
        )}
      </div>
    </div>
  )
}

export const IUIAsyncSelect = React.forwardRef((props, ref) => {
  const {
    additional = {
      page: 0,
      tabelName: '',
      product: '',
      placeholder: '',
      userId: 0
    },
    label = null,
    secondaryLabel = null,
    description = null,
    required = false,
    value = '',
    name,
    onValueChange = () => {}, // param: { _name, _value, _isError, _errorMsg, _obj, _event } = () => {},
    inputType = false,
    loadOption = () => {},
    isError = false,
    errorMsg = '',
    readOnly = false,
    placeHolderCheck = false,
    hasInfoIcon = false,
    tooltipText = '',
    id = '',
    isMulti = false, // Multi select Option
    disableText, // on hovering of disabled ones, this text will show on tooltip
    isMove = false,
    selectCTA = null,
    clearCache = 0,
    customLoadingText = 'Loading...',
    customLoadingTextStyle = {}
  } = props
  const [localValue, onChange] = useState(value) // need for onchange async select , need to pass setstate
  const handleOnChange = (selectedOption) => {
    onChange(selectedOption)
    const param = {
      _name: name,
      _value: selectedOption,
      _isError: null,
      _errorMsg: null,
      _obj: null,
      _event: null
    }
    onValueChange(param)
  }

  useEffect(() => {
    onChange(value)
  }, [value])
  const MenuList = wrapMenuList((props) =>
    CustomMenuList(props, selectCTA, customLoadingText, customLoadingTextStyle)
  )

  useEffect(() => {
    console.log(clearCache, 'ClearCache console logged')
  }, [clearCache])

  return (
    <div className={readOnly ? classes.readOnly : ''}>
      <IUILabel
        label={label}
        secondaryLabel={secondaryLabel}
        description={description}
        required={required}
        hasInfoIcon={hasInfoIcon}
        tooltipText={tooltipText}
      />
      <Tooltip
        placement='top-start'
        disableHoverListener={!readOnly}
        disableFocusListener={!readOnly}
        disableTouchListener={!readOnly}
        title={disableText}
      >
        <div className={isMove ? classes.diableShow : classes.diableShowHide}>
          <AsyncPaginate
            selectRef={ref}
            id={id}
            placeholder={additional.placeholder}
            value={localValue}
            loadOptions={loadOption}
            debounceTimeout={500}
            additional={additional}
            className={clsx(
              classes.selectContainer,
              {
                [classes.inputContainer]: inputType
              },
              { [classes.errorBorderCls]: isError },
              { [classes.placeholderColor]: placeHolderCheck },
              { [classes.multiContainer]: isMulti }
            )}
            name={name}
            onChange={handleOnChange}
            isDisabled={readOnly}
            isMulti={isMulti}
            components={{
              MenuList
            }}
            cacheUniqs={[clearCache]}
            menuPlacement='auto'
            noOptionsMessage={() => 'No se encontraron opciones'}
            loadingMessage={() => 'Cargando opciones..'}
            classNamePrefix='iui'
            styles={{
              indicatorSeparator: (base) => ({
                ...base,
                display: 'none'
              }),
              input: (base) => ({
                ...base
              })
            }}
          />
        </div>
      </Tooltip>
      {isError && (
        <Typography
          variant='body2'
          component='span'
          className={classes.errorTextCls}
        >
          {errorMsg}
        </Typography>
      )}
    </div>
  )
})
