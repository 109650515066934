import { Box } from '@material-ui/core'
import React from 'react'
import classes from './IUILayout.module.scss'
import { PropTypes } from 'prop-types'

export const IUILayout = ({
  header = null,
  main = null,
  mainFullWidth = false,
  rightPanel = null,
  backcolor = '#ffffff',
  overlay = false,
  headerActions = null
}) => {
  return (
    <Box
      sx={{
        paddingX: { sm: '40px', xl: '140px' },
        paddingY: '24px',
        maxWidth: '1920px',
        marginX: 'auto',
        backgroundColor: backcolor,
        minHeight: '100vh'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: '48px'
        }}
      >
        <Box sx={{ flexGrow: 1, maxWidth: { lg: '640px', xl: '880px' } }}>
          {header}
        </Box>
        <Box sx={{ width: { md: '312px', xl: '316px' } }}>{headerActions}</Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: '48px'
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            maxWidth: {
              lg: !mainFullWidth ? '640px' : 'auto',
              xl: !mainFullWidth ? '880px' : 'auto'
            }
          }}
        >
          <Box className={classes.contentMain}>
            {overlay && <Box className={classes.overlay} />}
            <Box>{main}</Box>
          </Box>
        </Box>
        {!mainFullWidth && (
          <Box sx={{ width: { md: '312px', xl: '316px' } }}>{rightPanel}</Box>
        )}
      </Box>
    </Box>
  )
}
IUILayout.propTypes = {
  header: PropTypes.element,
  main: PropTypes.element,
  mainFullWidth: PropTypes.bool,
  rightPanel: PropTypes.element,
  backcolor: PropTypes.string,
  overlay: PropTypes.bool,
  headerActions: PropTypes.element
}
