import {
  FormControl,
  Typography,
  Select,
  MenuItem,
  OutlinedInput
} from '@material-ui/core'
import { ExpandMoreIcon } from '../../utils/icons/ExpandMoreIcon'
import React, { useEffect, Fragment } from 'react'
import classes from './IUIMultiInput.module.scss'
import { checkEmptyObject } from '../../utils/_helpers'
import clsx from 'clsx'
import { PropTypes } from 'prop-types'
import { IUIBox } from '../IUIBox'
import { Error as ErrorIcon } from '../../icons/svg/ErrorIcon'
import { v4 as uuid } from 'uuid'
import { IUILabel } from '../IUILabel'

export const IUIMultiInput = (props) => {
  const {
    id = '',
    name = '',
    label = '',
    handleChangeInput,
    defaultInputValue = '',
    isError = false,
    errorMsg = '',
    selectList = [],
    required = false,
    readOnly = false,
    placeholder = '',
    className = {},
    style,
    customSelectHandler = () => {},
    selectValue = 'USD',
    showSelect = false,
    descriptionComponent = null,
    validation = {},
    customHandler = () => {},
    labelCustomClass = {},
    inputProps = {},
    inputCustomClass = {},
    labelInfoIcon = false,
    labelInfoText = ''
  } = props
  const [inputValue, setInputValue] = React.useState('')

  const handleChange = (event) => {
    customSelectHandler(event.target.name, event.target.value)
  }

  const inputFocusOut = (e) => {
    let _isError = false
    let _errorMsg = ''
    const _value = validation.isNumber ? Number(e.target.value) : e.target.value
    if (required) {
      if (_value.trim() === '') {
        _isError = true
        _errorMsg = 'Value cannot be empty'
      } else {
        if (!checkEmptyObject(validation) && validation.regexOnBlur) {
          if (
            typeof validation.regexOnBlur === 'object' &&
            validation.regexOnBlur.length
          ) {
            for (let i = 0; i < validation.regexOnBlur.length; i++) {
              if (!validation.regexOnBlur[i].regex.test(_value)) {
                _isError = true
                _errorMsg = validation.regexOnBlur[i].message
                break
              }
            }
          } else {
            if (!validation.regexOnBlur.test(_value)) {
              _isError = true
              _errorMsg = 'Invalid input'
            }
          }
        }
      }
    } else {
      if (_value.trim() !== '') {
        if (validation.regexOnBlur) {
          if (
            typeof validation.regexOnBlur === 'object' &&
            validation.regexOnBlur.length
          ) {
            for (let i = 0; i < validation.regexOnBlur.length; i++) {
              if (!validation.regexOnBlur[i].regex.test(_value)) {
                _isError = true
                _errorMsg = validation.regexOnBlur[i].message
                break
              }
            }
          } else {
            if (!validation.regexOnBlur.test(_value)) {
              _isError = true
              _errorMsg = 'Invalid input'
            }
          }
        }
      }
    }

    if (typeof customHandler === 'function')
      customHandler(name, inputValue, _isError, _errorMsg)
  }

  const inputOnChange = (e) => {
    const _value = validation.isNumber ? Number(e.target.value) : e.target.value
    if (String(_value).trim() === '') {
      setInputValue('')
    } else {
      if (!checkEmptyObject(validation) && validation.regexOnChange) {
        if (!validation.regexOnChange.test(_value)) return false
        const _split = String(_value).split('.')
        if (
          validation.maxLength &&
          String(_split[0]).length > validation.maxLength
        )
          return false
      }

      setInputValue(e.target.value.trim())
    }
    handleChangeInput(e.target.name, _value, errorMsg)
  }

  useEffect(() => {
    setInputValue(defaultInputValue)
  }, [defaultInputValue])

  return (
    <>
      <IUILabel
        label={label}
        required={required}
        className={labelCustomClass}
        hasInfoIcon={labelInfoIcon}
        tooltipText={labelInfoText}
      />
      <IUIBox display='flex'>
        <FormControl
          disabled={readOnly}
          style={{
            width: '29%',
            minWidth: '50px',
            borderRadius: '0px 6px 6px 0px'
          }}
        >
          {showSelect ? (
            <Select
              id={id}
              displayEmpty
              name={name}
              value={selectValue}
              style={{ ...style }}
              onChange={handleChange}
              IconComponent={(_props) => (
                <div className={classes.pr_icon} {..._props}>
                  <ExpandMoreIcon width='10' height='10' />
                </div>
              )}
              className={clsx(
                classes.customSelect,
                className,
                'iui__font--size-base normal iui__font--color-black500 iui__font--weight-400',
                { [classes.menuTitleColor]: selectValue === '' },
                { [classes.readOnly]: readOnly }
              )}
              variant='outlined'
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
                getContentAnchorEl: null
              }}
            >
              {selectList.map((_c, _i) => {
                return (
                  <MenuItem
                    key={uuid()}
                    id={_c.id}
                    value={_c.name}
                    className={clsx(
                      classes.backgroundColor,
                      classes.popOverText
                    )}
                  >
                    <Typography variant='inherit'>{_c.name}</Typography>{' '}
                  </MenuItem>
                )
              })}
            </Select>
          ) : (
            <OutlinedInput
              id={id}
              className={clsx(
                classes.inputStyle,
                classes.staticContent,
                classes.inputSelectStyle,
                classes.inputSelectorCls,
                classes.marginVal,
                'iui__font--size-base normal iui__font--color-black500 iui__font--weight-400',
                // { [classes.inputBorder]: !isError },
                { [classes.readOnly]: readOnly }
              )}
              name={name}
              value={selectValue}
              readOnly
              disabled
              autoComplete='off'
            />
          )}
        </FormControl>

        <FormControl fullWidth variant='outlined'>
          {/* <IUILabel label={label} /> */}
          <OutlinedInput
            id={id}
            className={clsx(
              classes.inputStyle,
              classes.inputSelectorCls,
              classes.marginVal,
              'iui__font--size-base normal iui__font--color-black500 iui__font--weight-400',
              { [classes.inputBorder]: !isError },
              { [classes.errorBorder]: isError },
              { [classes.readOnly]: readOnly },
              inputCustomClass && inputCustomClass
            )}
            inputProps={inputProps}
            name={name}
            placeholder={placeholder}
            value={inputValue}
            // margin='none'
            error={isError}
            onBlur={inputFocusOut}
            onChange={inputOnChange}
            required={required}
            autoComplete='off'
          />
        </FormControl>
      </IUIBox>
      <IUIBox padding='4px'>
        {isError && (
          <>
            <span className='iui__mr-3'>
              <ErrorIcon />
            </span>

            <Typography
              variant='body2'
              component='span'
              className={
                (classes.errorTextCls,
                'iui__font--para-sm normal iui__font--color-red600')
              }
            >
              {errorMsg}
            </Typography>
          </>
        )}
      </IUIBox>
      {descriptionComponent}
    </>
  )
}
IUIMultiInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  defaultInputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  errorMsg: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  selectValue: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  showSelect: PropTypes.bool,
  isError: PropTypes.bool,
  validation: PropTypes.object,
  style: PropTypes.object,
  selectList: PropTypes.array,
  descriptionComponent: PropTypes.element,
  handleChangeInput: PropTypes.func,
  customSelectHandler: PropTypes.func,
  customHandler: PropTypes.func
}
