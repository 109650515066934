import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'

const useStyles = makeStyles({
  root: {
    padding: '0 9px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    borderRadius: '50%',
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    // backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '1px auto #EC5D25',
      outlineOffset: 1
    },
    'input:hover ~ &': {
      // outline: '1px auto #374151',
      backgroundColor: '#ebf1f5'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: '#E5E7EB'
    }
  },
  checkedIcon: {
    backgroundColor: '#EC5D25',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#EC5D25'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: '#E5E7EB'
    }
  },
  small: {
    height: 16,
    width: 16,
    '&:before': {
      width: 16,
      height: 16
    }
  },
  medium: {
    height: 20,
    width: 20,
    '&:before': {
      width: 20,
      height: 20
    }
  }
})

export function IUIPrimaryRadio(props) {
  const classes = useStyles()
  const { size = 'small' } = props
  return (
    <Radio
      className={classes.root}
      disableRipple
      // color='default'
      checkedIcon={
        <span
          className={clsx(
            classes.icon,
            classes.checkedIcon,
            size === 'small' ? classes.small : classes.medium
          )}
        />
      }
      icon={
        <span
          className={clsx(
            classes.icon,
            size === 'small' ? classes.small : classes.medium
          )}
        />
      }
      {...props}
    />
  )
}
