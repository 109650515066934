import React from 'react'
import Button from '@material-ui/core/Button'
import clsx from 'clsx'
import { PropTypes } from 'prop-types'
import classes from './IUIDropdownButton.module.scss'
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography
} from '@material-ui/core'
import { ExpandMoreIcon } from '../../utils/icons/ExpandMoreIcon'
import { IUITypography } from '../IUITypography'

export const IUIDropdownButton = (props) => {
  const {
    buttonText = '',
    disableRipple = true,
    onClickHandler = () => {},
    menuListInfo = [],
    endIcon = <ExpandMoreIcon width='10' height='10' />,
    outlinedBtn = true,
    placement = 'auto-center',
    className,
    menuListClass
  } = props
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
    onClickHandler(event)
  }

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }
  const handleMenuClick = (val) => {
    setOpen(false)
    if (val.disable) return false
    if (val.handler && typeof val.handler === 'function') val.handler()
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])
  return (
    <React.Fragment>
      <Button
        endIcon={endIcon}
        className={clsx(classes.muiBtn, className, {
          [classes.outlinedBtn]: outlinedBtn
        })}
        disableRipple={disableRipple}
        variant='outlined'
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
      >
        {buttonText}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: '9' }}
        placement={placement}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper className={classes.menuListContainer} elevation={0}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  className={clsx(
                    classes.menuList,
                    {
                      [classes.outlinedBtn]: outlinedBtn
                    },
                    menuListClass
                  )}
                  id='menu-list-grow'
                  onKeyDown={(e) => handleListKeyDown(e)}
                >
                  {menuListInfo.map((val, _i) => {
                    return (
                      <MenuItem
                        onClick={() => handleMenuClick(val)}
                        key={_i}
                        className={clsx(
                          classes.menuItemContainer,
                          val.disable && 'iui__cursor-not-allowed'
                        )}
                        disableRipple
                      >
                        <Typography
                          className={clsx(
                            classes.menuItemtext,
                            val && val.disable && 'iui__opacity--60'
                          )}
                        >
                          {val.label}
                        </Typography>
                        {val.subText && (
                          <IUITypography
                            variant='subtitle2'
                            className={clsx(
                              classes.menuItemtext,
                              classes.subTxt
                            )}
                          >
                            {val.subText}
                          </IUITypography>
                        )}
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  )
}

IUIDropdownButton.propTypes = {
  disableRipple: PropTypes.bool,
  outlinedBtn: PropTypes.bool,
  buttonText: PropTypes.any,
  closeHandler: PropTypes.func,
  placement: PropTypes.string,
  className: PropTypes.string,
  menuListClass: PropTypes.string,
  menuListInfo: PropTypes.array,
  endIcon: PropTypes.element
}
