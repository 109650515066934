import React from 'react'

export const Download = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      fill='none'
      viewBox='0 0 11 12'
    >
      <path
        fill='#6B7280'
        d='M8.559 4h-1.06V.667C7.499.3 7.199 0 6.832 0H4.165C3.8 0 3.5.3 3.5.667V4h-1.06a.669.669 0 00-.474 1.14l3.06 3.06c.26.26.68.26.94 0l3.06-3.06C9.445 4.72 9.152 4 8.56 4zM.832 10.667c0 .366.3.666.667.666h8c.366 0 .666-.3.666-.666 0-.367-.3-.667-.666-.667h-8c-.367 0-.667.3-.667.667z'
      />
    </svg>
  )
}
