import React from 'react'
import { Box, Typography } from '@material-ui/core'
import classes from './IUIPageHeader.module.scss'
// components
import { IUIBreadCrumb } from '../IUIBreadcrumb'
import { IUIAlert } from '../IUIAlert'
import { IUIBox } from '../IUIBox'
// Icons
import { ChevronNext } from '../../utils/icons/ChevronNextIcon'
import { ToastInfoIcon } from '../../utils/icons/ToastInfoIcon'
import { PropTypes } from 'prop-types'
import { NavigateNextIcon } from '../../utils/icons/NavigateNextIcon'

export const IUIPageHeader = (props) => {
  const {
    title = '',
    // lockIcon = <LockOutlined style={{ color: '#6B7280' }} />,
    lockIcon = null,
    description = '',
    showAlert = false,
    alertTitle = '',
    showAlertDesc = false,
    alertDesc = '',
    showBreadCrumb = true,
    breadCrumbsArray = [],
    breadCrumbForward = true,
    breadCrumbSeparator = <ChevronNext />,
    breadCrumbStyle = { color: '#6B7280' },
    secondaryHeaderComponent = null, // Pass components
    headerActions = null, // Pass components --switch, button
    backToLink = null,
    className = ''
  } = props
  return (
    <Box className={className}>
      {showBreadCrumb && (
        <Box className={classes.breadCrumbBox}>
          <IUIBreadCrumb
            forward={breadCrumbForward}
            separator={breadCrumbSeparator}
            breadCrumbs={breadCrumbsArray}
            style={breadCrumbStyle}
          />
        </Box>
      )}
      {backToLink && (
        <IUIBox>
          <Typography
            variant='body2'
            className='iui__font--para-base medium iui__font--color-theme iui__cursor-pointer iui__d-inlineFlex iui__align-items-center'
            onClick={backToLink.callback}
          >
            <NavigateNextIcon width='6' height='10' />{' '}
            <Typography
              variant='inherit'
              className='iui__font--para-base medium iui__ml-3'
              onClick={backToLink.callback}
            >
              {backToLink.label}
            </Typography>
          </Typography>
        </IUIBox>
      )}
      <Box className={classes.pageHeaderBox}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'center',
            flexDirection: { xs: 'column', sm: 'row' }
          }}
        >
          <Box
            display='flex'
            alignItems='center'
            justifyContent='flex-start'
            gridGap='12.5px'
          >
            <Typography varient='h1' className={classes.title}>
              {title}
            </Typography>
            {lockIcon}
          </Box>
          <Box>{headerActions}</Box>
        </Box>
      </Box>
      {description && (
        <Typography className={classes.desc}>{description}</Typography>
      )}
      {showAlert && (
        <IUIAlert
          title={alertTitle}
          showDesc={showAlertDesc}
          description={alertDesc}
          icon={<ToastInfoIcon color='#6B7280' />}
        />
      )}
      {secondaryHeaderComponent}
    </Box>
  )
}
IUIPageHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  alertTitle: PropTypes.string,
  alertDesc: PropTypes.string,
  breadCrumbsArray: PropTypes.array,
  showAlert: PropTypes.bool,
  showAlertDesc: PropTypes.bool,
  showBreadCrumb: PropTypes.bool,
  secondaryHeaderComponent: PropTypes.element,
  headerActions: PropTypes.element,
  breadCrumbForward: PropTypes.bool,
  breadCrumbSeparator: PropTypes.element,
  breadCrumbStyle: PropTypes.object,
  backToLink: PropTypes.shape({
    url: PropTypes.string,
    callback: PropTypes.func
  })
}
