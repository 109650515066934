import React from 'react'
import { IUIModal, IUIButton, IUITypography } from '@itilite/iuistyles'
import styles from '../GettingStartedWith.module.scss'

export default function infoModal(props) {
  return (
    <IUIModal
      isOpen={props.show}
      className={styles.infoModal}
      closeHandler={() => props.onHide(props.name)}
      width="auto"
      modalBodyStyle={{ display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center', height: '200px', padding: '0 40px' }}
      footerStyle={{ display: "none" }}
    >
      <IUITypography className={styles.infoText}>
        Please reach out to the onboarding manager at{' '}
        <a
          href='mailto:onboarding@itilite.com'
          style={{ color: '#ea580c', textDecoration: 'none' }}
        >
          onboarding@itilite.com
        </a>{' '}
        to configure the {props.name}.
      </IUITypography>
      <IUIButton style={{ backgroundColor: "#EC5D25", color: '#fff', textTransform: 'unset', marginTop: '2rem', fontSize: '1rem' }} color='#fff' type="contained" buttonText='Go Back' onClick={() => props.onHide(props.name)}></IUIButton>
    </IUIModal>
  )
}
