import React from 'react'

export function Email(props) {
  const {
    color = '#1F2937',
    secondaryColor = '#F97316',
    width = '48',
    height = '48'
  } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 49 49'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.7273 25.2603L5.22885 17.2659L6.30651 15.5811L18.805 23.5754C19.8429 24.2393 21.1812 24.2011 22.1796 23.4791L30.7716 17.2651C31.2192 16.9415 31.8443 17.0419 32.168 17.4894C32.4916 17.9369 32.3912 18.562 31.9437 18.8857L23.3516 25.0997C21.6877 26.3031 19.4572 26.3667 17.7273 25.2603Z'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.76767 19.3516C4.76767 15.4856 7.78063 12.3516 11.4973 12.3516H28.3682C32.0849 12.3516 35.0978 15.4856 35.0978 19.3516V25.5081H33.1751V19.3516C33.1751 16.5901 31.0229 14.3516 28.3682 14.3516H11.4973C8.84254 14.3516 6.69043 16.5901 6.69043 19.3516V29.8516C6.69043 32.613 8.84254 34.8516 11.4973 34.8516H28.3056L28.3703 36.8516H11.4973C7.78063 36.8516 4.76767 33.7176 4.76767 29.8516V19.3516Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.5252 28.6563C28.5252 25.8948 30.7638 23.6562 33.5252 23.6562H34.2679V25.6562H33.5252C31.8684 25.6562 30.5252 26.9994 30.5252 28.6563V33.7089C30.5252 33.971 30.4223 34.2227 30.2386 34.4097L29.5379 35.1229H34.2844V37.1229H27.1539C26.751 37.1229 26.3875 36.8812 26.2317 36.5097C26.0759 36.1382 26.1582 35.7095 26.4405 35.4222L28.5252 33.2999V28.6563Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M39.9696 28.6592C39.9696 25.8978 37.731 23.6592 34.9696 23.6592H34.2344V25.6592H34.9696C36.6265 25.6592 37.9696 27.0023 37.9696 28.6592V33.7118C37.9696 33.9737 38.0723 34.2251 38.2557 34.412L38.9558 35.1259H34.2067V37.1259H41.3372C41.7399 37.1259 42.1033 36.8843 42.2592 36.513C42.4151 36.1417 42.3331 35.7131 42.0512 35.4256L39.9696 33.3033V28.6592Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M33.4241 36.126C33.4241 36.5883 33.7891 36.9365 34.2068 36.9365C34.6244 36.9365 34.9894 36.5883 34.9894 36.126H36.9894C36.9894 37.6635 35.7582 38.9365 34.2068 38.9365C32.6554 38.9365 31.4241 37.6635 31.4241 36.126H33.4241Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M33.5035 23.8389C33.5035 24.2101 33.8039 24.5371 34.2063 24.5371C34.6087 24.5371 34.9091 24.2101 34.9091 23.8389L35.5035 23.8389C35.5035 23.1343 34.937 22.5371 34.2063 22.5371C33.4756 22.5371 32.9091 23.1343 32.9091 23.8389L33.5035 23.8389Z'
        fill={color}
      />
    </svg>
  )
}
