import React from 'react'
import { IUIBox, IUITypography } from '@itilite/iuistyles'
import clsx from 'clsx'
import {
  SetUpTeam,
  ManageCategories,
  CreateExpensePolicies,
  SetExpenseApprovals,
  CreateExpenseReport,
  ArrowRightAltIcon
} from '../GettingStartedSvg'
import styles from '../GettingStartedWith.module.scss'

function Expense({
  expense_links: {
    set_team,
    manage_categories,
    create_policies,
    set_approvals,
    create_expense_report
  }
}) {
  const linksArray = [
    {
      primary_text: 'Set up your team',
      secondary_text:
        'Invite users to your organization account and assign approvers',
      link: set_team,
      image: <SetUpTeam />
    },
    {
      primary_text: 'Manage categories',
      secondary_text:
        'Add and manage categories to categorize expenses and track business spend',
      link: manage_categories,
      image: <ManageCategories />
    },
    {
      primary_text: 'Create expense policies',
      secondary_text:
        'Create expense policies and define rules for expense claims',
      link: create_policies,
      image: <CreateExpensePolicies />
    },
    {
      primary_text: 'Set expense approvals',
      secondary_text:
        'Set up customized expense approval process for expense claims',
      link: set_approvals,
      image: <SetExpenseApprovals />
    }
  ]

  return (
    <IUIBox component='div' className={styles.gettingStartedWithExpenseContainer}>
      <IUIBox component='div' className={styles.parentContainer}>
        <IUIBox component='div' className={styles.parentSubContainer}>
          <IUITypography className={styles.header}>Get started with Expense</IUITypography>
          <IUIBox component='div' className={styles.gs__linksWrapper}>
            {linksArray.map((d, _i) => {
              return (
                <a href={d.link} className={styles.gs__linksBox} key={_i}>
                  <IUIBox component='div' className={styles.linksContainer}>
                    <IUIBox component='div' className='link-icon'>
                      <IUIBox component='div' className={clsx(styles.image__wrapper, styles.borderradius__circle)}>
                        {d.image !== '' ? d.image : null}
                      </IUIBox>
                    </IUIBox>
                    <IUIBox component='div' className={styles.linkDesc}>
                      <IUITypography className={styles.gs__linkTitle}>{d.primary_text}</IUITypography>
                      <IUITypography className={styles.gs__linkDesc}>{d.secondary_text}</IUITypography>
                    </IUIBox>
                    <IUIBox component='div' className={clsx(styles.linkAction, styles.arrow_right)}>
                      <ArrowRightAltIcon />
                    </IUIBox>
                  </IUIBox>
                  {_i < linksArray.length - 1 ? <hr /> : null}
                </a>
              )
            })}
          </IUIBox>
        </IUIBox>
      </IUIBox>
      <IUIBox component='div' className={styles.parentContainer}>
        <IUIBox component='div' className={styles.parentSubContainer}>
          <h3 className={styles.header_2}>All set with Expense! You can also...</h3>
          <IUIBox component='div' className={styles.gs__linksWrapper}>
            <a href={create_expense_report} className={styles.gs__linksBox}>
              <IUIBox component='div' className={styles.linksContainer}>
                <IUIBox component='div' className={styles.linkIcon}>
                  <IUIBox component='div' className={clsx(styles.image__wrapper, styles.borderradius__circle,styles.expense_report_icon)}>
                    <CreateExpenseReport />
                  </IUIBox>
                </IUIBox>
                <IUIBox component='div' className={styles.linkDesc}>
                  <IUITypography className={styles.gs__linkTitle}>Create expense report</IUITypography>
                  <IUITypography className={styles.gs__linkDesc}>
                    Create an expense report and start submitting expenses that
                    you have already incurred
                  </IUITypography>
                </IUIBox>
                <IUIBox component='div' className={clsx(styles.linkAction, styles.arrow_right)}>
                  <ArrowRightAltIcon />
                </IUIBox>
              </IUIBox>
            </a>
          </IUIBox>
        </IUIBox>
      </IUIBox>
    </IUIBox>
  )
}

export default Expense
