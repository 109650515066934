import { IconButton, Typography, Grid } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import styles from './IUIFileUpload.module.scss'
import clsx from 'clsx'
import { CrossIcon } from '../../utils/icons/CrossIcon'
import Tooltip from '@material-ui/core/Tooltip'
import { UserIcon } from '../../utils/icons/UserIcon'

export const IUIFileUpload = ({
  multiple = false,
  title = 'Upload File',
  fileType = '',
  onValueChange = () => {}, // param: { _name, _value, _isError, _errorMsg, _obj, _event }
  name = '',
  maxFiles = 1,
  maxFileSize = 5, // 5 mb
  value = [],
  isError = false,
  errorMsg = '',
  readOnly = false,
  hasInfoIcon = false,
  tooltipText = '',
  id = '',
  disableText = '',
  buttonType = '' // options - profile
}) => {
  const maxNumFiles = multiple ? maxFiles : 1
  const ref = useRef()
  const [files, setFiles] = useState(value)
  const [localErrMsg, setLocalErr] = useState('')
  const [dataUri, setDataUri] = useState('')

  const fileTypeArr = fileType.split(',')
  const fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event) => {
        resolve(event.target.result)
      }
      reader.readAsDataURL(file)
    })
  const handleRemoveFile = (e, removeIndex) => {
    e.stopPropagation()
    e.preventDefault()
    if (readOnly) return
    ref.current.value = ''
    const _files = files.filter((file, i) => i !== removeIndex)
    const param = {
      _name: name,
      _value: [..._files],
      _isError: null,
      _errorMsg: null,
      _obj: {
        isDeleted: true
      },
      _event: null
    }
    onValueChange(param)
  }

  const handleOnChange = (event) => {
    if (readOnly) return
    if (event?.target?.files[0]?.name.split('.').length > 2) {
      setLocalErr(`Enter valid image`)
      return
    }
    if (event.target.files.length + files.length > maxNumFiles) {
      setLocalErr(`You can only select upto ${maxNumFiles} files`)
      return
    }
    for (let i = 0; i < event.target.files.length; i++) {
      if (!fileTypeArr.includes(event.target.files[i].type)) {
        setLocalErr(
          `'${event.target.files[i].name}'- file type ${event.target.files[i].type} is not Allowed.`
        )
        return
      }
      if (event.target.files[i].size > maxFileSize * 1000000) {
        setLocalErr(
          `'${event.target.files[i].name}'- file should be less than ${maxFileSize} MB.`
        )
        return
      }
    }
    // Read file by reader
    if (buttonType === 'profile') {
      const file = event.target.files[0]
      if (!file) {
        setDataUri('')
        return
      }
      fileToDataUri(file).then((dataUri) => {
        setDataUri(dataUri)
      })
    }

    // update the root lvl state
    const param = {
      _name: name,
      _value: [...Array.from(event.target.files), ...files],
      _isError: null,
      _errorMsg: null,
      _obj: {
        isUpload: true
      },
      _event: null
    }
    onValueChange(param)
    setLocalErr('')
  }

  const handleOpenFile = (file) => {
    const param = {
      _name: name,
      _value: [...files],
      _isError: null,
      _errorMsg: null,
      _obj: {
        openFile: true,
        file: file
      },
      _event: null
    }
    onValueChange(param)
  }
  useEffect(() => {
    // whenever root lvl state/props get change , update the local state
    let fileUrl = ''
    value.map((file, i) => {
      if (file.file_type === 'profile_pic') {
        fileUrl = file.presigned_url
      }
    })
    if (fileUrl !== '') {
      setDataUri(fileUrl)
    }
    setFiles(value)
  }, [value])

  const formatLongFileName = (name) => {
    if (name.length > 25) {
      name = name.slice(0, 24) + '...'
    }
    return name
  }

  return (
    <React.Fragment>
      {files.length < maxNumFiles && (
        <React.Fragment>
          {buttonType === 'profile' ? (
            <Grid
              container
              direction='row'
              justifyContent='flex-start'
              alignItems='center'
            >
              <Grid item>
                <label
                  id={id}
                  htmlFor={`${name}-file-upload`}
                  className={clsx(styles.fileUploadContainerForProfile, {
                    [styles.readOnly]: readOnly
                  })}
                >
                  <UserIcon width='18' height='20' />
                </label>
              </Grid>
              <Grid item>
                <label
                  id={id}
                  htmlFor={`${name}-file-upload`}
                  className={clsx(styles.fileGuideText, {
                    [styles.readOnly]: readOnly
                  })}
                >
                  Upload photo
                </label>
              </Grid>
            </Grid>
          ) : (
            <label
              id={id}
              htmlFor={`${name}-file-upload`}
              className={clsx(styles.fileUploadContainer, {
                [styles.readOnly]: readOnly
              })}
            >
              {title}
            </label>
          )}
        </React.Fragment>
      )}

      {files.map((file, i) => {
        return (
          <div key={String(i + 1)}>
            {buttonType === 'profile' ? (
              <Grid
                container
                direction='row'
                justifyContent='flex-start'
                alignItems='center'
              >
                <Grid item>
                  <img
                    className={clsx(styles.fileUploadContainerForProfile)}
                    alt='avatar'
                    src={dataUri}
                  />
                </Grid>
                <Grid item>
                  <div
                    className={clsx(styles.fileGuideText)}
                    onClick={(e) => handleRemoveFile(e, i)}
                  >
                    Remove photo
                  </div>
                </Grid>
              </Grid>
            ) : (
              <div
                className={clsx(styles.fileUploadContainer, {
                  [styles.inputSize]: true
                })}
                onClick={() => handleOpenFile(file)}
              >
                <a>{formatLongFileName(file.name)}</a>

                {!readOnly && (
                  <IconButton
                    onClick={(e) => handleRemoveFile(e, i)}
                    className={styles.iconContainer}
                  >
                    <CrossIcon width='10' height='10' />
                  </IconButton>
                )}
              </div>
            )}

            {isError && typeof errorMsg === 'object' && (
              <Typography
                variant='body2'
                component='div'
                className={styles.errorTextCls}
              >
                {errorMsg[file.name]}
              </Typography>
            )}
          </div>
        )
      })}
      <Tooltip
        placement='top-start'
        disableHoverListener={!readOnly}
        disableFocusListener={!readOnly}
        disableTouchListener={!readOnly}
        title={disableText}
      >
        <input
          id={`${name}-file-upload`}
          type='file'
          accept={fileType}
          style={{ display: 'none' }}
          multiple={multiple}
          ref={ref}
          onChange={handleOnChange}
          name={name}
          disabled={readOnly}
        />
      </Tooltip>
      {(isError || localErrMsg) && (
        <Typography
          variant='body2'
          component='div'
          className={styles.errorTextCls}
        >
          {typeof errorMsg === 'string' && errorMsg ? errorMsg : localErrMsg}
        </Typography>
      )}
    </React.Fragment>
  )
}
