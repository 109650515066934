import React from 'react'
import clsx from 'clsx'
import Checkbox from '@material-ui/core/Checkbox'
import styles from './IUICheckbox.module.scss'

export const IUICheckBox = (props) => {
  const {
    handleCheckBoxHandler = () => {},
    disabled = false,
    checked = false,
    id = '',
    name,
    className = '',
    style = {},
    size = 'small'
  } = props

  return (
    <React.Fragment>
      <Checkbox
        className={clsx(
          styles.root,
          className,
          size === 'big' && [styles.bigCheckBox]
        )}
        disableRipple
        checkedIcon={
          <span
            className={clsx(
              styles.icon,
              styles.checkedIcon,
              size === 'big' && [styles.bigCheckBox]
            )}
          />
        }
        icon={
          <span
            className={clsx(
              styles.icon,
              size === 'big' && [styles.bigCheckBox]
            )}
          />
        }
        inputProps={{ 'aria-label': 'decorative checkbox' }}
        onChange={handleCheckBoxHandler}
        disabled={disabled}
        checked={checked}
        id={id}
        name={name}
        style={{ ...style }}
      />
    </React.Fragment>
  )
}
