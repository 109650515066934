import React from 'react'
import styles from './IUIEmptyField.module.scss'
import clsx from 'clsx'
import { IUIBox } from '../IUIBox'
import { IUITypography } from '../IUITypography'

export const IUIEmptyField = (props) => {
  const {
    iconComponent = null,
    description = '',
    actionComponents = [],
    actionButtonsGap = ''
  } = props
  return (
    <IUIBox
      component='div'
      className={clsx(styles.dashedLine, styles.emptyFieldContainer)}
      display='flex'
      justifyContent='center'
      alignItems='center'
      padding='40px'
    >
      {iconComponent && (
        <IUIBox
          display='flex'
          justifyContent='center'
          alignItems='center'
          className={styles.iconComponent}
        >
          {iconComponent}
        </IUIBox>
      )}
      {description && (
        <IUIBox
          display='flex'
          justifyContent='center'
          alignItems='center'
          width='inherit'
        >
          <IUITypography className={styles.emptyFieldDescription}>
            {description}
          </IUITypography>
        </IUIBox>
      )}
      {actionComponents.length > 0 && (
        <IUIBox
          display='flex'
          justifyContent='center'
          alignItems='center'
          className={styles.actionComponent}
          style={{ gap: actionButtonsGap }}
        >
          {actionComponents.map((action, index) => {
            return action.item(index)
          })}
        </IUIBox>
      )}
    </IUIBox>
  )
}
