/* eslint-disable camelcase */
import React, { useState } from 'react'
import {
  SetUpTeam,
  CreateNewTrip,
  AddMoneyToYourWallet,
  CreateExpensePolicies,
  SetExpenseApprovals,
  SetCompanySettings,
  ArrowRightAltIcon,
  SetUpPaymentmethod
} from '../GettingStartedSvg'
import infoModal from '../Common/Modal'
import { IUIBox, IUITypography } from '@itilite/iuistyles'
import styles from '../GettingStartedWith.module.scss'
import clsx from 'clsx'

function Travel({
  payment_method,
  travel_links: {
    set_team,
    create_policies,
    set_approvals,
    create_trip,
    add_money,
	payment_methods,
    settings
  }
}) {
  const [showModal, setShowModal] = useState({
    policy: false,
    approvals: false
  })

  const [name, setName] = useState('')

  const modalToggler = (name) => {
    setName((prev) => {
      prev = name

      return prev
    })
    setShowModal({ ...showModal, [name]: !showModal[name] })
  }

  const linksArray = [
    {
      primary_text: 'Set up your team',
      secondary_text:
        'Invite users to your organization account and assign approvers',
      link: set_team,
      image: <SetUpTeam />
    },
    {
      primary_text: 'Create travel policies',
      secondary_text:
        'Create travel policies to define rules for flights and hotels',
      link: create_policies,
      image: <CreateExpensePolicies />
    },
    {
      primary_text: 'Set travel approvals',
      secondary_text:
        'Set up customized travel approval process based on various rules',
      link: set_approvals,
      image: <SetExpenseApprovals />
    }
  ]

  if (payment_method === 0) {
    linksArray.splice(1, 0, {
      primary_text: 'Add money to your wallet',
      secondary_text: `Add money to your company wallet to be used for your user's travel needs`,
      link: add_money,
      image: <AddMoneyToYourWallet />
    })
  } else if (payment_method === 1) {
    linksArray.push({
      primary_text: 'Set company settings',
      secondary_text:
        'Set up company preferences including ERP & HRMS integrations, Budget & Alerts',
      link: settings,
      image: <SetCompanySettings />
    })
  } else if (payment_method === 2) {
    linksArray.push({
      primary_text: 'Setup payment method',
      secondary_text:
        'Enable bookings by adding a payment method for your employees',
      link: payment_methods,
      image: <SetUpPaymentmethod />
    })
  }
  const linkContainer = (d, _i) => (
    <a href={d.link} className={styles.gs__linksBox} key={_i}>
      <IUIBox component='div' className={styles.linksContainer}>
        <IUIBox component='div' className={styles.linkIcon}>
          <IUIBox component='div' className={clsx(styles.image__wrapper, styles.borderradius__circle)}>
            {d.image !== '' ? d.image : null}
          </IUIBox>
        </IUIBox>
        <IUIBox component='div' className={styles.linkDesc}>
          <IUITypography className={styles.gs__linkTitle}>{d.primary_text}</IUITypography>
          <IUITypography className={styles.gs__linkDesc}>{d.secondary_text}</IUITypography>
        </IUIBox>
        <IUIBox component='div' className={clsx(styles.linkAction, styles.arrow_right)}>
          <ArrowRightAltIcon />
        </IUIBox>
      </IUIBox>
      {_i < linksArray.length - 1 ? <hr /> : null}
    </a>
  )

  const divContainer = (d, _i, name) => (
    <IUIBox component='div' className={styles.gs__linksBox} key={_i} onClick={() => modalToggler(name)}>
      <IUIBox component='div' className={styles.linksContainer}>
        <IUIBox component='div' className={styles.linkIcon}>
          <IUIBox component='div' className={clsx(styles.image__wrapper, styles.borderradius__circle)}>
            {d.image !== '' ? d.image : null}
          </IUIBox>
        </IUIBox>
        <IUIBox component='div' className={styles.linkDesc}>
          <IUITypography className={styles.gs__linkTitle}>{d.primary_text}</IUITypography>
          <IUITypography className={styles.gs__linkDesc}>{d.secondary_text}</IUITypography>
        </IUIBox>
        <IUIBox component='div' className={clsx(styles.linkAction, styles.arrow_right)}>
          <ArrowRightAltIcon />
        </IUIBox>
      </IUIBox>
      {_i < linksArray.length - 1 ? <hr /> : null}
    </IUIBox>
  )

  return (
    <IUIBox component='div' className={styles.gettingStartedWithTravelContainer}>
      <IUIBox component='div' className={styles.parentContainer}>
        <IUIBox component='div' className={styles.parentSubContainer}>
          <IUITypography className={styles.header}>Get started with Travel</IUITypography>
          <IUIBox component='div' className={styles.gs__linksWrapper}>
            {linksArray.map((d, _i) => {
              const name =
                d.primary_text.includes('policies') && create_policies === ''
                  ? 'policy details'
                  : d.primary_text.includes('approvals') && set_approvals === ''
                    ? 'approval rules'
                    : ''
              return (d.primary_text.includes('policies') &&
                create_policies === '') ||
                (d.primary_text.includes('approvals') && set_approvals === '')
                ? divContainer(d, _i, name)
                : linkContainer(d, _i)
            })}
          </IUIBox>
        </IUIBox>
      </IUIBox>
      <IUIBox component='div' className={styles.parentContainer}>
        <IUIBox component='div' className={styles.parentSubContainer}>
          <IUITypography variant="h3" className={styles.header_2}>All set with Travel! You can also...</IUITypography>
          <IUIBox component='div' className={styles.gs__linksWrapper}>
            <a href={create_trip} className={styles.gs__linksBox}>
              <IUIBox component='div' className={styles.linksContainer}>
                <IUIBox component='div' className={styles.linkIcon}>
                  <IUIBox component='div' className={clsx(styles.image__wrapper, styles.borderradius__circle)}>
                    <CreateNewTrip />
                  </IUIBox>
                </IUIBox>
                <IUIBox component='div' className={styles.linkDesc}>
                  <IUITypography className={styles.gs__linkTitle}>Create new trip</IUITypography>
                  <IUITypography className={styles.gs__linkDesc}>
                    Create a new trip and start booking flights, hotels and car
                    rentals for your travel needs
                  </IUITypography>
                </IUIBox>
                <IUIBox component='div' className={clsx(styles.linkAction, styles.arrow_right)}>
                  <ArrowRightAltIcon />
                </IUIBox>
              </IUIBox>
            </a>
          </IUIBox>
        </IUIBox>
      </IUIBox>
      {infoModal({
        name,
        show: showModal[name],
        onHide: modalToggler
      })}
    </IUIBox>
  )
}

export default Travel
