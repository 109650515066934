import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Button, ButtonGroup, makeStyles } from '@material-ui/core'
import { v4 as uuid } from 'uuid'
import classes from './IUIButtonGroup.module.scss'
import { IUIblack500 } from '../../theming/variables/js/_colors.variables'
// import { IUITooltip } from '../IUITooltip'
// import { IUITypography } from '../IUITypography'

const useStyle = makeStyles({
  root: {
    '&$disabled': {
      color: IUIblack500,
      opacity: 0.5,
      border: `1px solid ${IUIblack500}`,
      pointerEvents: 'auto',
      cursor: 'not-allowed'
    }
  },
  disabled: {
    color: IUIblack500,
    opacity: 0.5,
    border: `1px solid ${IUIblack500}`,
    pointerEvents: 'auto',
    cursor: 'not-allowed'
  }
})
// const ButtonWrapper = (props) => {
//   const { tooltipWrap = false } = props
//   if (tooltipWrap) {
//     return (
//       <IUITooltip tooltipText={tooltipWrap} arrow placement='top-start'>
//         <Fragment>{props.children}</Fragment>
//       </IUITooltip>
//     )
//   }
//   return props.children
// }
export const IUIButtonGroup = (props) => {
  const classesStyle = useStyle()
  const {
    list = [],
    variant = 'outlined',
    size = 'large',
    orientation = 'horizontal',
    activeButtonName = '',
    fullWidth = false,
    disabled = false,
    disableElevation = false,
    disableFocusRipple = false,
    disableRipple = false
  } = props

  return (
    <React.Fragment>
      <ButtonGroup
        variant={variant}
        size={size}
        orientation={orientation}
        fullWidth={fullWidth}
        disableElevation={disableElevation}
        disableFocusRipple={disableFocusRipple}
        disabled={disabled}
        disableRipple={disableRipple}
      >
        {list.length &&
          list.map((_button, _i) => {
            return (
              <Button
                key={uuid()}
                disabled={_button.disabled}
                onClick={() => {
                  _button.submitHandler(_button.name, _i)
                }}
                name={_button.name}
                className={clsx(
                  _button.className,
                  classes.buttonSingle,
                  activeButtonName === _button.name && classes.activeButton,
                  activeButtonName === _button.name &&
                    'iui__border--solid_orange600-2 iui__font--color-theme iui__bg--white',
                  classesStyle.root
                )}
                classes={{
                  root: classesStyle.root,
                  disabled: classesStyle.disabled
                }}
              >
                {_button.label}
              </Button>
            )
          })}
      </ButtonGroup>
    </React.Fragment>
  )
}

IUIButtonGroup.propTypes = {
  list: PropTypes.array,
  fullWidth: PropTypes.bool,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  activeButtonName: PropTypes.string,
  disabled: PropTypes.bool,
  disableElevation: PropTypes.bool,
  disableFocusRipple: PropTypes.bool,
  disableRipple: PropTypes.bool
}
