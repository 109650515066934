import React from 'react'

export const ToastInfo = (props) => {
  const { color = '#CA8A04' } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill={color}
        d='M13 7a1 1 0 11-2 0 1 1 0 012 0zM10 10a1 1 0 100 2h1v3h-1a1 1 0 100 2h4a1 1 0 100-2h-1v-4a1 1 0 00-1-1h-2z'
      />
      <path
        fill={color}
        d='M2 12c0 5.523 4.477 10 10 10s10-4.477 10-10S17.523 2 12 2 2 6.477 2 12zm10-8a8 8 0 110 16 8 8 0 010-16z'
      />
    </svg>
  )
}
