import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import { Box } from '@material-ui/core'

export const IUISkeleton = (props) => {
  const { data = [], gap = '', wrap = '', flexDirection = 'column' } = props
  return (
    <Box
      display='flex'
      gridGap={gap}
      flexWrap={wrap}
      flexDirection={flexDirection}
    >
      {data &&
        data.map((value, index) => {
          return (
            <Skeleton
              variant={value.variant}
              height={value.height}
              width={value.width}
              style={{
                marginTop: value.style.top,
                marginBottom: value.style.bottom
              }}
              key={index}
            />
          )
        })}
    </Box>
  )
}
