import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import Checkbox from '@material-ui/core/Checkbox'
import styles from './IUICheckboxButton.module.scss'
import { OutlinedInput } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'

export const IUICheckboxButton = (props) => {
  const {
    isCheckbox = true,
    onValueChange = () => {}, // param: { _name, _value, _isError, _errorMsg, _obj, _event }
    disabled = false,
    checkboxFlag = false,
    id = '',
    name = '',
    disableText = ''
  } = props
  const [isChecked, setIsChecked] = useState(!!checkboxFlag)

  useEffect(() => {
    // update local state whenever root lvl state/props get changed
    setIsChecked(!!checkboxFlag)
  }, [checkboxFlag])

  const handleCheckBoxHandler = (e) => {
    e.stopPropagation()
    if (disabled) return
    // setIsChecked(!isChecked)
    const param = {
      _name: null,
      _value: null,
      _isError: null,
      _errorMsg: null,
      _obj: null,
      _event: e
    }
    onValueChange(param)
  }

  const handleParentClick = (e) => {
    e.stopPropagation()
    if (disabled) return
    if (isCheckbox) {
      // setIsChecked(!isChecked)
      const _e = {
        target: {
          name: id,
          value: props.label.toLowerCase()
        }
      }
      const param = {
        _name: null,
        _value: null,
        _isError: null,
        _errorMsg: null,
        _obj: null,
        _event: _e
      }
      onValueChange(param)
    }
  }

  return (
    <React.Fragment>
      <Tooltip
        disableHoverListener={!disabled}
        disableFocusListener={!disabled}
        disableTouchListener={!disabled}
        placement='top-start'
        title={disableText}
      >
        <OutlinedInput
          onClick={handleParentClick}
          id={id}
          name={name}
          value={props.label}
          className={clsx(
            styles.largeInput,
            { [styles.inputSelectorCls]: !isChecked },
            {
              [styles.borderColorCls]: isChecked,
              [styles.readOnly]: disabled
            }
          )}
          startAdornment={
            isCheckbox && (
              <Checkbox
                className={styles.root}
                disableRipple
                checkedIcon={
                  <span className={clsx(styles.icon, styles.checkedIcon)} />
                }
                icon={<span className={styles.icon} />}
                inputProps={{ 'aria-label': 'decorative checkbox' }}
                onChange={handleCheckBoxHandler}
                // disabled={disabled}
                checked={isChecked}
                value={props.label.toLowerCase()}
                name={id}
              />
            )
          }
          readOnly
          disabled={disabled}
        />
      </Tooltip>
    </React.Fragment>
  )
}
