import React from 'react'

export const CloudUpload = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      version='1'
      viewBox='0 0 512 512'
    >
      <path
        fill='#6B7280'
        d='M2415 4313c-500-63-925-411-1071-879l-25-80-86-13c-116-16-258-63-374-122-564-289-830-937-633-1544 120-367 422-673 794-803 178-62 220-67 607-67 339 0 352 1 379 21 53 39 69 71 69 134s-16 95-69 134c-26 20-44 21-374 27-371 6-397 9-553 68-123 46-269 153-372 270-302 347-302 895 0 1241 183 211 401 318 690 338 99 8 116 12 147 36 46 35 53 49 76 156 78 366 342 642 709 742 102 28 342 31 445 5 219-55 416-183 548-356 66-87 141-245 167-350 12-47 21-92 21-101 0-10 13-35 29-57 37-51 87-70 206-77 416-25 768-325 872-742 24-95 24-333 0-428-89-356-349-621-706-718-68-19-113-22-423-27-330-6-348-7-374-27-53-39-69-71-69-134s16-95 69-134c26-20 42-21 330-24 421-4 562 20 791 133 566 280 840 931 648 1539-54 171-159 347-292 488-186 198-451 338-718 380l-72 11-21 71c-63 209-204 425-379 579-182 162-429 274-674 306-74 10-246 12-312 4z'
        transform='matrix(.1 0 0 -.1 0 512)'
      />
      <path
        fill='#6B7280'
        d='M2495 3026c-16-8-222-207-457-442-415-418-427-430-433-475-17-118 91-214 202-179 29 10 102 76 315 288l278 276 2-1036c3-1029 3-1037 24-1064 39-53 71-69 134-69s95 16 134 69c21 27 21 35 24 1064l2 1036 278-276c234-233 284-278 320-289 116-34 227 77 193 194-11 36-72 102-434 466-239 239-436 429-454 437-40 17-89 17-128 0z'
        transform='matrix(.1 0 0 -.1 0 512)'
      />
    </svg>
  )
}
