import { AccordionDetails } from '@material-ui/core'
import React, { Fragment } from 'react'
import clsx from 'clsx'
import styles from './IUIAccordionDetails.module.scss'

export const IUIAccordionDetails = (props) => {
  const { className = '', style = {} } = props

  return (
    <Fragment>
      <AccordionDetails classes={{ root: clsx(styles.iuiAccordionDetails, className) }} style={{ ...style }}>
        {props.children}
      </AccordionDetails>
    </Fragment>
  )
}
