import React, { Fragment } from 'react'
import { IUIBox } from '../IUIBox'
import { IUITypography } from '../IUITypography'
import { IUIDropdownButton } from '../IUIDropdownButton'
import { MoreVertIcon } from '../../icons/svg/MoreVertIcon'
import { Add } from '../../icons/svg/AddIcon'
import classes from './IUIPaymentCard.module.scss'
import clsx from 'clsx'
import { PropTypes } from 'prop-types'

export const IUIPaymentCard = (props) => {
  const {
    defaultCard = false,
    card = true,
    callback = null,
    menuListInfo = [],
    cardNumber = '0000',
    cardExpiry = { month: '00', year: '0000' },
    cardType = 'is_nothing'
  } = props
  const addCardRedirect = () => {
    if (typeof callback === 'function') callback()
  }
  return (
    <>
      {card ? (
        <IUIBox
          className={clsx(classes.paymentCard, {
            [classes.defaultCard]: defaultCard
          })}
        >
          <IUIBox className={classes.cardActions}>
            <IUIBox className={classes.frontAction}>
              <IUIBox className={classes.mainAction}>
                <IUITypography variant='caption' className={classes.actionText}>
                  Auto-charge enabled
                </IUITypography>
              </IUIBox>
              {defaultCard && (
                <IUIBox className={classes.mainAction}>
                  <IUITypography
                    variant='caption'
                    className={classes.actionText}
                  >
                    Default card
                  </IUITypography>
                </IUIBox>
              )}
            </IUIBox>
            {!defaultCard && (
              <IUIBox className={classes.backAction}>
                <IUIDropdownButton
                  buttonText={<MoreVertIcon />}
                  onClickHandler={(event) => console.log('event', event)}
                  menuListInfo={menuListInfo}
                  endIcon={null} //
                  outlinedBtn={false}
                  className={classes.customDropdown}
                  placement='bottom-end'
                />
              </IUIBox>
            )}
          </IUIBox>
          <IUIBox className={classes.cardDetails}>
            <IUIBox className={classes.cardNumber}>
              <IUIBox variant='button' className={classes.cardDetailsText}>
                ****
              </IUIBox>{' '}
              <IUIBox variant='button' className={classes.cardDetailsText}>
                ****
              </IUIBox>
              <IUIBox variant='button' className={classes.cardDetailsText}>
                ****
              </IUIBox>
              <IUIBox className={classes.cardDetailsText}>{cardNumber}</IUIBox>
            </IUIBox>
            <IUIBox className={clsx(classes.cardImage, classes[cardType])} />
          </IUIBox>
          <IUIBox className={classes.cardExpiry}>
            <IUITypography variant='body2' className={classes.cardDetailsText}>
              {cardExpiry.month}
            </IUITypography>
            <IUITypography variant='body2' className={classes.cardDetailsText}>
              /
            </IUITypography>
            <IUITypography variant='body2' className={classes.cardDetailsText}>
              {cardExpiry.year}
            </IUITypography>
          </IUIBox>
        </IUIBox>
      ) : (
        <IUIBox className={classes.addCard} onClick={addCardRedirect}>
          <IUIBox>
            <Add width='28' height='28' className={classes.addIcon} />
          </IUIBox>
          <IUITypography variant='button' className={classes.addCardText}>
            Add new card
          </IUITypography>
        </IUIBox>
      )}
    </>
  )
}
IUIPaymentCard.propTypes = {
  defaultCard: PropTypes.bool,
  addCardLink: PropTypes.string,
  cardNumber: PropTypes.string,
  cardExpiry: PropTypes.object,
  cardType: PropTypes.string,
  card: PropTypes.bool,
  menuListInfo: PropTypes.array
}
