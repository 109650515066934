import { AccordionSummary } from '@material-ui/core'
import React, { Fragment } from 'react'
import { ExpandLessIcon } from '../../utils/icons/ExpandLessIcon'
import clsx from 'clsx'
import classes from './IUIAccordionSummary.module.scss'

export const IUIAccordionSummary = (props) => {
  const {
    expandIcon = <ExpandLessIcon width='12px' height='7px' />,
    className = '',
    style = {}
  } = props

  return (
    <Fragment>
      <AccordionSummary
        expandIcon={expandIcon}
        className={clsx(className, classes.iuiAccordionSummary)}
        style={{ ...style }}
      >
        {props.children}
      </AccordionSummary>
    </Fragment>
  )
}
