import { Divider } from '@material-ui/core'
import React, { Fragment } from 'react'
import { PropTypes } from 'prop-types'

export const IUIDivider = (props) => {
  const {
    className = '',
    variant = 'middle',
    orientation = 'horizontal',
    light = true,
    style = {}
  } = props

  return (
    <Fragment>
      <Divider
        className={className}
        variant={variant}
        orientation={orientation}
        light={light}
        style={{ ...style }}
      />
    </Fragment>
  )
}

IUIDivider.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['middle', 'inset', 'fullWidth']),
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  light: PropTypes.bool,
  style: PropTypes.object
}
