import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography
} from '@material-ui/core'
import React from 'react'
import { IUIBox } from '../IUIBox'
import styles from './IUICard.module.scss'
import clsx from 'clsx'

export const IUICard = (props) => {
  const {
    variant = 'outlined',
    illustration = '',
    illustrationTextComponent = null,
    title = '',
    titleClassName = '',
    rootClassName = '',
    cardHeaderClassName = '',
    description = '',
    descClassName = '',
    actionComponent = null,
    width = '',
    padding = '',
    height = '',
    headerComponentTitle = null
  } = props
  return (
    <Card
      variant={variant}
      className={clsx(styles.cardRoot, rootClassName)}
      style={{
        width: width,
        padding: padding,
        height: height
      }}
    >
      {headerComponentTitle && (
        <CardHeader
          className={cardHeaderClassName}
          title={headerComponentTitle}
        />
      )}
      <CardContent className={styles.content}>
        <IUIBox
          display='flex'
          justifyContent='space-between'
          alignItems='flex-start'
        >
          {illustration}
          {illustrationTextComponent}
        </IUIBox>
        <IUIBox
          display='flex'
          flexDirection='column'
          gap='8px'
          padding='15px 0px 0px 0px'
        >
          <Typography className={clsx(titleClassName, styles.title)}>
            {title}
          </Typography>
          <Typography className={clsx(descClassName, styles.desc)}>
            {description}
          </Typography>
        </IUIBox>
      </CardContent>
      {actionComponent && <CardActions>{actionComponent}</CardActions>}
    </Card>
  )
}
