import React, { useCallback, useState, useEffect } from 'react'
import {
  InputAdornment,
  Select,
  MenuItem,
  OutlinedInput,
  Typography,
  Box
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ExpandMoreIcon } from '../../utils/icons/ExpandMoreIcon'
import {
  AsYouType,
  getCountryCallingCode,
  isValidNumber,
  parseIncompletePhoneNumber,
  isSupportedCountry
} from 'libphonenumber-js'

import classes from './IUIPhone.module.scss'
import clsx from 'clsx'
import CountryCodeMenuItem from './IUICountryCodeMenuItem'

const useMenuItemStyles = makeStyles({
  menuItem: {
    '& .flag::after': {
      content: 'attr(data-country-code)'
    }
  },
  selected: {
    '& .flag::after': {
      content: "''"
    }
  }
})

const ITEM_HEIGHT = 70
const ITEM_PADDING_TOP = 10
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 500
    }
  },
  variant: 'menu',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  getContentAnchorEl: null
}

export const IUIPhone = ({
  value = '',
  selectedCountryOverride = 'IN', // init selectedCountry state
  list: countryOptions,
  name = '',
  onValueChange = () => {}, // param: { _name, _value, _isError, _errorMsg, _obj, _event }
  validation = {},
  fullWidth = false,
  isError = false,
  errorMsg = '',
  required = false,
  readOnly = false,
  id = '',
  className = "",
  isBlurValidationOff = false,
  inputProps = {}
}) => {
  const [selectedCountry, setSelectedCountry] = useState(
    selectedCountryOverride
  )

  const [phoneNumber, setPhoneNumber] = useState(value)
  const [code, setDialingCode] = useState(
    '+' + getCountryCallingCode(selectedCountryOverride)
  )
  const asYouType = new AsYouType(selectedCountry)

  useEffect(() => {
    // update local state whenever root lvl state/props get changed
    setSelectedCountry(selectedCountryOverride)
    setDialingCode('+' + getCountryCallingCode(selectedCountryOverride))
    setPhoneNumber(value)
  }, [selectedCountryOverride, value])

  const onPhoneChanged = (val) => {
    setPhoneNumber(val)
  }

  const menuItemClasses = useMenuItemStyles()

  // const inputPhone = useCallback((phoneNumber) => {
  //   asYouType.reset()
  //   return asYouType.input(phoneNumber)
  // }, [])

  // let val = inputPhone(phoneNumber)

  // if (asYouType?.getNumber?.()?.countryCallingCode === '1') {
  //   val = inputPhone(asYouType.getNationalNumber())
  //   console.log(val)
  // }

  const getParsedPhoneNumber = useCallback(() => {
    let parsed

    if (asYouType && asYouType.getNumber) {
      parsed = asYouType.getNumber()
    }
    return parsed
  }, [])

  const countryCodeChanged = useCallback(
    (ev) => {
      const newCountry = ev.target.value
      let code = '1'
      if (isSupportedCountry(newCountry)) {
        code = getCountryCallingCode(newCountry)
      } else {
        code = countryOptions.find((o) => o.code === newCountry).dial_code
      }
      setDialingCode('+' + code)
      onPhoneChanged('')
      setSelectedCountry(newCountry)
    },
    [phoneNumber, onPhoneChanged]
  )

  const phoneNumberChanged = useCallback(
    (ev) => {
      // const newPhone = parseIncompletePhoneNumber(
      //   ev.target.value[0] === '+' ? ev.target.value : code + ev.target.value
      // )
      // inputPhone(newPhone)
      const parsedPhone = getParsedPhoneNumber(phoneNumber)

      onPhoneChanged(
        parseIncompletePhoneNumber(
          ev.target.value[0] === '+' ? ev.target.value : code + ev.target.value
        )
      )

      if (parsedPhone) {
        let newCountryCode = parsedPhone.country
        if (parsedPhone.countryCallingCode === '44') {
          newCountryCode = 'GB'
        } else if (parsedPhone.countryCallingCode === '1') {
          newCountryCode = 'US'
        }
        setSelectedCountry(newCountryCode)
      }
    },
    [onPhoneChanged, getParsedPhoneNumber, phoneNumber]
  )
  const menuItems = countryOptions.map((k, i) => {
    if (isSupportedCountry(k.code)) {
      return (
        <MenuItem
          value={k.code}
          key={i}
          className={clsx(menuItemClasses.menuItem, classes.countryName)}
        >
          <CountryCodeMenuItem {...k} />
        </MenuItem>
      )
    } else {
      return false
    }
  })

  const validatePhoneNumber = (ev) => {
    var obj = {
      target: {
        code: selectedCountry,
        contact_country_code: code
      }
    }
    const _value = ev.target.value
    let _errorMsg = ''
    let _isError = false
    const newPhone = parseIncompletePhoneNumber(
      ev.target.value[0] === '+' ? ev.target.value : code + ev.target.value
    )
    // isValidNumberForRegion();
    // isPossibleNumber();+>
    // isSupportedCountry();

    if (String(_value).trim().length < 7 && String(_value).trim() !== '') {
      _errorMsg = 'Phone cannot be less than 7 digit'
      _isError = true
    }
    if (!isValidNumber(newPhone) && String(_value).trim() !== '') {
      _errorMsg = 'Please enter the valid phone number'
      _isError = true
    }
    if (String(_value).trim() === '' && required) {
      _errorMsg = 'Value cannot be empty'
      _isError = true
    }
    const param = {
      _name: name,
      _value: _value,
      _isError: _isError,
      _errorMsg: _errorMsg,
      _obj: obj.target,
      _event: null
    }
    onValueChange(param)
  }

  return (
    <Box>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <OutlinedInput
          id={id}
          value={
            phoneNumber[0] === '+'
              ? phoneNumber.split(code)[1]
              : phoneNumber.split(code)[0]
          }
          variant='outlined'
          onChange={(e) => {
            phoneNumberChanged(e)
            if (isBlurValidationOff) {
              validatePhoneNumber(e)
            }
          }}
          type='tel'
          className={clsx(
            classes.inputStyle,
            classes.inputSelectorCls,
            classes.marginVal,
            { [classes.fullWidth]: fullWidth },
            { [classes.inputBorder]: !isError },
            { [classes.errorBorder]: isError },
            { [classes.readOnly]: readOnly },
            className
          )}
          onBlur={(e) => {
            if (!isBlurValidationOff) {
              validatePhoneNumber(e)
            }
          }}
          name={name}
          // error={isError}
          readOnly={readOnly}
          inputProps={inputProps}
          startAdornment={
            <InputAdornment
              position='start'
              style={{ width: '113px', boxShadow: 'none' }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Select
                  disabled={readOnly}
                  fullWidth
                  disableUnderline
                  value={selectedCountry}
                  // className={inputClass.selectBorder}
                  IconComponent={(_props) => (
                    <div
                      {..._props}
                      style={{ right: '10px', top: 'calc(50% - 10px)' }}
                    >
                      <ExpandMoreIcon width='10' height='10' />
                    </div>
                  )}
                  onChange={countryCodeChanged}
                  MenuProps={MenuProps}
                  className={classes.flagContainer}
                >
                  {menuItems}
                </Select>
                <Box display='flex' width='38px' justifyContent='flex-end'>
                  <Typography className={classes.countryName}>
                    {code}
                  </Typography>
                </Box>
              </div>
            </InputAdornment>
          }
        />
        {isError && (
          <Typography
            variant='body2'
            component='span'
            className={classes.errorTextCls}
          >
            {errorMsg}
          </Typography>
        )}
      </div>
    </Box>
  )
}
