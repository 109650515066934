import { Typography } from '@material-ui/core'
import React from 'react'

export const IUITypography = (props) => {
  const { className = '', style = {} } = props
  return (
    <Typography {...props} className={className} style={{ ...style }}>
      {props.children}
    </Typography>
  )
}
