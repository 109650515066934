import React from 'react'

export const BoldInfo = (props) => {
  const { color = '#6B7280', width, height } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 20 22'
    >
      <path
        d='M13 6.99951C13 7.5518 12.5523 7.99951 12 7.99951C11.4477 7.99951 11 7.5518 11 6.99951C11 6.44723 11.4477 5.99951 12 5.99951C12.5523 5.99951 13 6.44723 13 6.99951Z'
        fill={color}
      />
      <path
        d='M10 9.99951C9.44772 9.99951 9 10.4472 9 10.9995C9 11.5518 9.44772 11.9995 10 11.9995H11V14.9995H10C9.44772 14.9995 9 15.4472 9 15.9995C9 16.5518 9.44772 16.9995 10 16.9995H14C14.5523 16.9995 15 16.5518 15 15.9995C15 15.4472 14.5523 14.9995 14 14.9995H13V10.9995C13 10.4472 12.5523 9.99951 12 9.99951H10Z'
        fill={color}
      />
      <path
        d='M2 11.9995C2 17.5224 6.47715 21.9995 12 21.9995C17.5228 21.9995 22 17.5224 22 11.9995C22 6.47666 17.5228 1.99951 12 1.99951C6.47715 1.99951 2 6.47666 2 11.9995ZM12 3.99951C16.4183 3.99951 20 7.58123 20 11.9995C20 16.4178 16.4183 19.9995 12 19.9995C7.58172 19.9995 4 16.4178 4 11.9995C4 7.58123 7.58172 3.99951 12 3.99951Z'
        fill={color}
      />
    </svg>
  )
}
