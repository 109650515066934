import { InputAdornment, OutlinedInput } from '@material-ui/core'
import React from 'react'
import clsx from 'clsx'
import classes from './IUISearch.module.scss'
import { SearchIcon } from '../../utils/icons/SearchIcon'

export const IUISearch = (props) => {
  const {
    placeholder = '',
    onChangeHandler = () => {},
    onBlurHandle = () => {},
    iconComponent = <SearchIcon width='20' height='20' />,
    width = false,
    value = '',
    className = '',
    style = {},
    onKeyPressHandle = () => {}
  } = props
  return (
    <OutlinedInput
      placeholder={placeholder}
      onChange={onChangeHandler}
      onBlur={onBlurHandle}
      onKeyPress={onKeyPressHandle}
      value={value}
      className={clsx(classes.inputbase, classes.inputSelectorCls, className)}
      startAdornment={
        <InputAdornment position='start'>{iconComponent}</InputAdornment>
      }
      style={{ width: width && width, ...style }}
      autoComplete='off'
    />
  )
}
