import React from 'react'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import classes from './IUITabs.module.scss'
import { AppBar } from '@material-ui/core'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index, fullWidth) {
  return {
    id: fullWidth ? `full-width-tab-${index}` : `simple-tab-${index}`,
    'aria-controls': fullWidth
      ? `full-width-tabpanel-${index}`
      : `simple-tabpanel-${index}`
  }
}

export const IUITabs = (props) => {
  // Here is the structure for tabs arrya
  // tabs = [
  //     {
  //         label: 'Tab 1',
  //         content: 'My Tab 1'
  //     }
  // ]
  const {
    tabs,
    className,
    customTabHandler,
    value,
    fullWidth = false,
    position = 'static'
  } = props

  const handleChange = (event, newValue) => {
    customTabHandler(newValue)
  }

  return (
    <Box className={className}>
      <AppBar
        position={position}
        color='default'
        className={classes.tabContainer}
      >
        <Tabs
          value={value}
          variant={fullWidth ? 'fullWidth' : 'standard'}
          onChange={handleChange}
        >
          {tabs.map((tab, i) => (
            <Tab key={i} label={tab.label} {...a11yProps(i, fullWidth)} />
          ))}
        </Tabs>
      </AppBar>

      <Box>
        {tabs.map((tab, i) => (
          <TabPanel key={i} value={value} index={i}>
            {tab.content}
          </TabPanel>
        ))}
      </Box>
    </Box>
  )
}
