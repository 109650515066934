import React from 'react'

export const CheckCircle = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill={props.color}
        d='M8 1C4.136 1 1 4.136 1 8s3.136 7 7 7 7-3.136 7-7-3.136-7-7-7zm3.707 5.707l-4 4a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L7 8.586l3.293-3.293a1 1 0 111.414 1.414z'
      />
    </svg>
  )
}
