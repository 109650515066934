import React from 'react'
import { Box } from '@material-ui/core'
import { PropTypes } from 'prop-types'

export function Lock(props) {
  const _fillColor = '#000'
  const iconSize = { width: 17, height: 17 }
  if (props.size === 'medium') {
    iconSize.width = 24
    iconSize.height = 24
  }
  return (
    <Box>
      <svg
        width={iconSize.width}
        height={iconSize.height}
        viewBox='0 0 17 17'
        fill={props.fillColor || _fillColor}
        xmlns='http://www.w3.org/2000/svg'
        style={{ verticalAlign: 'middle' }}
        className={props.className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.9922 6.12145H5.99219C5.71605 6.12145 5.49219 6.34531 5.49219 6.62145V12.1418C5.49219 12.418 5.71605 12.6418 5.99219 12.6418H11.9922C12.2683 12.6418 12.4922 12.418 12.4922 12.1418V6.62145C12.4922 6.34531 12.2683 6.12145 11.9922 6.12145ZM5.99219 4.62145C4.88762 4.62145 3.99219 5.51688 3.99219 6.62145V12.1418C3.99219 13.2464 4.88762 14.1418 5.99219 14.1418H11.9922C13.0968 14.1418 13.9922 13.2464 13.9922 12.1418V6.62145C13.9922 5.51688 13.0968 4.62145 11.9922 4.62145H5.99219Z'
          fill={props.fillColor || _fillColor}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.15623 8.89402C8.87322 8.89402 8.6438 9.12344 8.6438 9.40646C8.6438 9.68947 8.87322 9.91889 9.15623 9.91889C9.43925 9.91889 9.66867 9.68947 9.66867 9.40646C9.66867 9.12344 9.43925 8.89402 9.15623 8.89402ZM9.15623 10.894C9.97779 10.894 10.6438 10.228 10.6438 9.40646C10.6438 8.5849 9.97779 7.91889 9.15623 7.91889C8.33468 7.91889 7.66867 8.5849 7.66867 9.40646C7.66867 10.228 8.33468 10.894 9.15623 10.894Z'
          fill={props.fillColor || _fillColor}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.89707 2.89185C8.32542 2.89185 7.862 3.35526 7.862 3.92692V4.7864H6.362V3.92692C6.362 2.52684 7.49699 1.39185 8.89707 1.39185C10.2972 1.39185 11.4321 2.52684 11.4321 3.92692V5.11697H9.93215V3.92692C9.93215 3.35526 9.46873 2.89185 8.89707 2.89185Z'
          fill={props.fillColor || _fillColor}
        />
      </svg>
    </Box>
  )
}

Lock.propTypes = {
  size: PropTypes.oneOf(['medium', '']),
  fillColor: PropTypes.string
}
