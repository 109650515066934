export const IUIfontFamilyprimary = '`Inter`, sans-serif'

export const IUIfontSizexs = '0.75rem' // 12px
export const IUIfontSizesm = '0.875rem' // 14px
export const IUIfontSizebase = '1rem' // 16px
export const IUIfontSizelg = '1.125rem' // 18px
export const IUIfontSizexl = '1.25rem' // 20px
export const IUIfontSize2xl = '1.5rem' // 24px
export const IUIfontSize3xl = '1.875rem' // 30px
export const IUIfontSize4xl = '2.5rem' // 40px
export const IUIfontSize10 = '1rem'
export const IUIfontSize11 = '1.1rem'
export const IUIfontSize12 = '1.2rem'
export const IUIfontSize13 = '1.3rem'
export const IUIfontSize14 = '1.4rem'
export const IUIfontSize15 = '1.5rem'
export const IUIfontSize16 = '1.6rem'
export const IUIfontSize17 = '1.7rem'
export const IUIfontSize18 = '1.8rem'
export const IUIfontSize19 = '1.9rem'
export const IUIfontSize20 = '2rem'
export const IUIfontSize24 = '2.4rem'
export const IUIfontSize28 = '2.8rem'
export const IUIfontSize32 = '3.2rem'
export const IUIfontSize36 = '3.6rem'
export const IUIfontSize40 = '4rem'
export const IUIfontSize44 = '4.4rem'
export const IUIfontSize52 = '5.2rem'
export const IUIfontSize56 = '5.6rem'
export const IUIfontSize60 = '6rem'

export const IUIfontWeightthin = '200'
export const IUIfontWeightlight = '300'
export const IUIfontWeightnormal = '400'
export const IUIfontWeightmedium = '500'
export const IUIfontWeightsemibold = '600'
export const IUIfontWeightbold = '700'
export const IUIfontWeightextrabold = '800'
export const IUIfontWeightblack = '900'

//	Alternative
export const IUIfontWeight100 = '100'
export const IUIfontWeight200 = '200'
export const IUIfontWeight300 = '300'
export const IUIfontWeight400 = '400'
export const IUIfontWeight500 = '500'
export const IUIfontWeight600 = '600'
export const IUIfontWeight700 = '700'
export const IUIfontWeight800 = '800'
export const IUIfontWeight900 = '900'
