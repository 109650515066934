import React from 'react'
import { IconButton } from '@material-ui/core'
import clsx from 'clsx'
import classes from './IUIIconButton.module.scss'

export const IUIIconButton = (props) => {
  const {
    className = '',
    onClick = () => {},
    disableRipple = true,
    style = {}
  } = props

  return (
    <IconButton
      className={clsx(className, classes.removeHoverEffect)}
      onClick={(e) => onClick(e)}
      disableRipple={disableRipple}
      style={{ ...style }}
    >
      {props.children}
    </IconButton>
  )
}
