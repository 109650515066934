import { Tooltip, OutlinedInput } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import classes from './IUIOutlinedInput.module.scss'
import { checkEmptyObject } from '../../utils/_helpers'
import { Error as ErrorIcon } from '../../icons/svg/ErrorIcon'

import { IUILabel } from '../IUILabel'
import { IUIBox } from '../IUIBox'
import { IUITypography } from '../IUITypography'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  startAdornmentWidth: {
    width: (props) => `${props.startAdornmentWidth || 0}px !important`,
    flex: (props) => `0 0 ${props.startAdornmentWidth || 0}px !important`
  },
  endAdornmentWidth: {
    width: (props) => `${props.endAdornmentWidth || 0}px !important`,
    flex: (props) => `0 0 ${props.endAdornmentWidth || 0}px !important`
  }
})
export const IUIOutlinedInput = (props) => {
  const {
    label = '',
    secondaryLabel = null,
    labelDescription = null,
    labelInfoIcon = false,
    labelInfoText = '',
    id = '',
    name = '',
    value = '',
    required = false,
    readOnly = false,
    disabledHoverText = '',
    customInputClass = '',
    type = 'text',
    placeholder = '',
    isMultiline = false,
    multilineRows = 2,
    startAdornment = '',
    startAdornmentWidth = null,
    endAdornment = '',
    endAdornmentWidth = null,
    onValueChange = null,
    onEveryValueChange = null,
    isError = false,
    errorMsg = '',
    validation = {},
    style = {},
    autoComplete = '',
    __name=''
  } = props

  const [inputValue, setInputValue] = useState('')

  const inputOnChange = (e) => {
    const _value = validation.isNumber ? Number(e.target.value) : e.target.value
    if (String(_value).trim() === '') {
      if (typeof onEveryValueChange === 'function') {
        const param = {
          _name: name,
          _value: _value,
          _obj: null,
          _event: e
        }
        onEveryValueChange(param)
      }
      setInputValue('')
    } else {
      if (!checkEmptyObject(validation) && validation.regexOnChange) {
        if (!validation.regexOnChange.test(_value)) return false

        if (
          validation.maxLength &&
          String(_value).length > validation.maxLength
        )
          return false
      }

      if (typeof onEveryValueChange === 'function') {
        const param = {
          _name: name,
          _value: _value,
          _obj: null,
          _event: null
        }
        onEveryValueChange(param)
      }
      setInputValue(_value)
    }
  }
  const inputFocusOut = (e) => {
    let _isError = false
    let _errorMsg = ''
    const _value = validation.isNumber ? Number(e.target.value) : e.target.value
    if (required) {
      if (_value.trim() === '') {
        _isError = true
        _errorMsg = 'Value cannot be empty'
      } else {
        if (!checkEmptyObject(validation) && validation.regexOnBlur) {
          if (
            typeof validation.regexOnBlur === 'object' &&
            validation.regexOnBlur.length
          ) {
            for (let i = 0; i < validation.regexOnBlur.length; i++) {
              if (!validation.regexOnBlur[i].regex.test(_value)) {
                _isError = true
                _errorMsg = validation.regexOnBlur[i].message
                break
              }
            }
          } else {
            if (!validation.regexOnBlur.test(_value)) {
              _isError = true
              _errorMsg = 'Invalid input'
            }
          }
        }
      }
    } else {
      if (_value.trim() !== '') {
        if (validation.regexOnBlur) {
          if (
            typeof validation.regexOnBlur === 'object' &&
            validation.regexOnBlur.length
          ) {
            for (let i = 0; i < validation.regexOnBlur.length; i++) {
              if (!validation.regexOnBlur[i].regex.test(_value)) {
                _isError = true
                _errorMsg = validation.regexOnBlur[i].message
                break
              }
            }
          } else {
            if (!validation.regexOnBlur.test(_value)) {
              _isError = true
              _errorMsg = 'Invalid input'
            }
          }
        }
      }
    }

    if (typeof onValueChange === 'function') {
      const param = {
        _name: name,
        _value: _value,
        _isError: _isError,
        _errorMsg: _errorMsg,
        _obj: null,
        _event: null
      }
      onValueChange(param)
    }
  }

  const jsClasses = useStyles(props)

  useEffect(() => {
    setInputValue(value)

    return () => {
      setInputValue('')
    }
  }, [value])

  return (
    <IUIBox>
      <IUILabel
        label={label}
        secondaryLabel={secondaryLabel}
        description={labelDescription}
        required={required}
        hasInfoIcon={labelInfoIcon}
        tooltipText={labelInfoText}
      />
      <IUIBox>
        <Tooltip
          placement='top-start'
          disableHoverListener={!readOnly}
          disableFocusListener={!readOnly}
          disableTouchListener={!readOnly}
          title={disabledHoverText}
        >
          <React.Fragment>
            <IUIBox display='flex'>
              {startAdornment && (
                <OutlinedInput
                  readOnly
                  value={startAdornment}
                  className={clsx(
                    startAdornmentWidth !== 0 && jsClasses.startAdornmentWidth,
                    classes.inputStyle,
                    classes.startAdornment,
                    classes.pointerEvent__none
                  )}
                />
              )}
              <OutlinedInput
                id={id}
                value={inputValue}
                required={required}
                placeholder={placeholder}
                className={clsx(
                  customInputClass,
                  classes.inputStyle,
                  startAdornment && classes.mainInput_startAdornment,
                  endAdornment && classes.mainInput_endAdornment,
                  readOnly && classes.readOnly,
                  isError && classes.errorBorder
                )}
                error={isError}
                type={type}
                onChange={inputOnChange}
                onBlur={inputFocusOut}
                readOnly={readOnly}
                multiline={isMultiline}
                rows={multilineRows}
                style={{ ...style }}
                name={__name}
              />
              {endAdornment && (
                <OutlinedInput
                  readOnly
                  value={endAdornment}
                  className={clsx(
                    endAdornmentWidth !== 0 && jsClasses.endAdornmentWidth,
                    classes.inputStyle,
                    classes.endAdornment,
                    classes.pointerEvent__none
                  )}
                />
              )}
            </IUIBox>
          </React.Fragment>
        </Tooltip>

        {isError && (
          <IUITypography
            variant='caption'
            className={clsx(
              'iui__font--para-normal normal iui__font--color-red600 iui__d-flex iui__align-items-center iui__mt-4',
              classes.error
            )}
          >
            <IUIBox
              component='span'
              className='iui__d-flex iui__align-items-center iui__mr-2 iui__mb-1'
            >
              <ErrorIcon />
            </IUIBox>
            {errorMsg}
          </IUITypography>
        )}
      </IUIBox>
    </IUIBox>
  )
}

IUIOutlinedInput.propTypes = {
  /**
   * Add custom class to input field
   */
  customInputClass: PropTypes.string,
  /**
   * When input field is disable then tolltip text to show on hover
   */
  disabledHoverText: PropTypes.string,
  /**
   * Add end box containing text attached to input
   */
  endAdornment: PropTypes.string,
  /**
   * Any custom width to the content box
   */
  endAdornmentWidth: PropTypes.number,
  /**
   * error message to show for the input
   */
  errorMsg: PropTypes.string,
  /**
   * Unique idfor input
   */
  id: PropTypes.string,
  /**
   * if the input has error
   */
  isError: PropTypes.bool,
  /**
   * if the input is a textarea with multiline option
   */
  isMultiline: PropTypes.bool,
  /**
   * Label for input field
   */
  label: PropTypes.string,
  /**
   * If label has any description below it
   */
  labelDescription: PropTypes.string,
  /**
   * if label has Info icon at end that has tooltip functionality
   */
  labelInfoIcon: PropTypes.bool,
  /**
   * tooltip text for info icon
   */
  labelInfoText: PropTypes.string,
  /**
   * number of row if multiline is enabled
   */
  multilineRows: PropTypes.number,
  /**
   * name for input feild
   */
  name: PropTypes.string,
  /**
   * callback for value change in input
   */
  onValueChange: PropTypes.fun,
  /**
   * placeholder for input
   */
  placeholder: PropTypes.string,
  /**
   * if input is a required field or not
   */
  required: PropTypes.bool,
  /**
   * should the input be read only
   * If true then input field cannot be edited
   */
  readOnly: PropTypes.bool,
  /**
   * if provides it will have a helper text for label enclosed inside small bracket
   */
  secondaryLabel: PropTypes.string,
  /**
   * Add start box containing text attached to input
   */
  startAdornment: PropTypes.string,
  /**
   * Any custom width to the content box
   */
  startAdornmentWidth: PropTypes.number,
  /**
   * Any style override for input
   */
  style: PropTypes.object,
  /**
   * type of input field
   */
  type: PropTypes.oneOf(['text', 'number', 'password', 'email']),
  /**
   * Value for input field
   */
  value: PropTypes.string,
  /**
   * validations object required for input field
   */
  validation: PropTypes.object
}
