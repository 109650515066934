import React from 'react'

export function Concierge(props) {
  const {
    color = '#1F2937',
    secondaryColor = '#F97316',
    width = '49',
    height = '49'
  } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 49 49'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M40.9794 19.8164H6.86768L6.86767 34.5556H40.9794L40.9794 19.8164ZM6.86768 17.8164C5.76311 17.8164 4.86768 18.7118 4.86768 19.8164V34.5556C4.86768 35.6602 5.7631 36.5556 6.86767 36.5556H40.9794C42.084 36.5556 42.9794 35.6602 42.9794 34.5556V19.8164C42.9794 18.7118 42.084 17.8164 40.9794 17.8164H6.86768Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.6578 9.24609C13.2217 9.24609 12.8359 9.52869 12.7043 9.94448L9.79443 19.1429L7.88757 18.5397L10.7975 9.34124C11.1921 8.0939 12.3495 7.24609 13.6578 7.24609H23.9235V9.24609H13.6578Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.1894 9.24609C34.6255 9.24609 35.0113 9.52869 35.1428 9.94448L38.0527 19.1429L39.9596 18.5397L37.0497 9.34124C36.6551 8.0939 35.4977 7.24609 34.1894 7.24609H23.9236V9.24609H34.1894Z'
        fill={color}
      />
      <path
        d='M17.5027 26.4416C17.5027 27.905 16.3393 29.0913 14.9042 29.0913C13.4691 29.0913 12.3057 27.905 12.3057 26.4416C12.3057 24.9783 13.4691 23.792 14.9042 23.792C16.3393 23.792 17.5027 24.9783 17.5027 26.4416Z'
        fill={secondaryColor}
      />
      <path
        d='M35.5414 26.4416C35.5414 27.905 34.378 29.0913 32.9429 29.0913C31.5078 29.0913 30.3444 27.905 30.3444 26.4416C30.3444 24.9783 31.5078 23.792 32.9429 23.792C34.378 23.792 35.5414 24.9783 35.5414 26.4416Z'
        fill={secondaryColor}
      />
      <path
        d='M8.13928 37.0872C8.13928 35.743 9.22898 34.6533 10.5732 34.6533C11.9174 34.6533 13.0071 35.743 13.0071 37.0872V40.2299C13.0071 41.5741 11.9174 42.6638 10.5732 42.6638C9.22898 42.6638 8.13928 41.5741 8.13928 40.2299V37.0872Z'
        fill={color}
      />
      <path
        d='M34.1384 37.0872C34.1384 35.743 35.2281 34.6533 36.5723 34.6533C37.9165 34.6533 39.0062 35.743 39.0062 37.0872V40.2299C39.0062 41.5741 37.9165 42.6638 36.5723 42.6638C35.2281 42.6638 34.1384 41.5741 34.1384 40.2299V37.0872Z'
        fill={color}
      />
    </svg>
  )
}
