import React from 'react'
const countrySvg = (isError) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      style={{
        height: '1.15rem',
        width: 'auto'
      }}
    >
      <path
        stroke={isError ? '#E11D48' : '#6B7280'}
        strokeLinejoin='round'
        strokeWidth='2'
        d='M4 5a1 1 0 011-1h15.018L17.94 6.811a2 2 0 000 2.378L20.018 12H5a1 1 0 01-1-1V5z'
      />
      <path
        stroke={isError ? '#E11D48' : '#6B7280'}
        strokeLinecap='round'
        strokeWidth='2'
        d='M4 20v-9'
      />
    </svg>
  )
}

const locationSvg = (isError) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      style={{
        height: '1.15rem',
        width: 'auto'
      }}
    >
      <path
        stroke={isError ? '#E11D48' : '#6B7280'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M12 12.8c1.347 0 2.438-1.075 2.438-2.4 0-1.326-1.091-2.4-2.437-2.4-1.346 0-2.438 1.074-2.438 2.4 0 1.325 1.091 2.4 2.438 2.4z'
      />
      <path
        stroke={isError ? '#E11D48' : '#6B7280'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M12 4a6.552 6.552 0 00-4.596 1.875A6.35 6.35 0 005.5 10.4c0 1.514.327 2.504 1.219 3.6L12 20l5.281-6c.892-1.096 1.219-2.086 1.219-3.6a6.35 6.35 0 00-1.904-4.525A6.552 6.552 0 0012 4v0z'
      />
    </svg>
  )
}

const regionSvg = (isError) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      style={{
        height: '1.15rem',
        width: 'auto'
      }}
    >
      <circle cx='12.342' cy='12.342' r='8' stroke='#6B7280' strokeWidth='2' />
      <path
        stroke={isError ? '#E11D48' : '#6B7280'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M11.933 4.269A15.148 15.148 0 009.824 12c0 2.733.731 5.412 2.11 7.731m.818-15.462A15.148 15.148 0 0114.86 12c0 2.733-.73 5.412-2.108 7.731m-6.999-3.72h13.741'
      />
      <path
        stroke={isError ? '#E11D48' : '#6B7280'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M5.258 8.98h14.236'
      />
    </svg>
  )
}
const removeSvg = (isError) => (
  <svg width={9} height={8} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M.47.235a.8.8 0 0 1 1.132 0L4.236 2.87 6.871.235a.8.8 0 0 1 1.131 1.132L5.368 4l2.634 2.634a.8.8 0 1 1-1.131 1.132L4.236 5.132 1.602 7.767A.8.8 0 1 1 .471 6.635l2.634-2.634L.471 1.367a.8.8 0 0 1 0-1.132Z'
      fill={isError ? '#E11D48' : '#6B7280'}
    />
  </svg>
)

export { countrySvg, locationSvg, regionSvg, removeSvg }
