/* eslint-disable react/jsx-key */
import React, { useState, useEffect, useRef } from 'react'
import {
  AsyncPaginate,
  wrapMenuList,
  reduceGroupedOptions
} from 'react-select-async-paginate'

import { IUILabel } from '../IUILabel/index.js'
import classes from './IUIAsyncMultiSelect.module.scss'
import clsx from 'clsx'
import { Typography, MenuItem, Box, FormLabel } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { components } from 'react-select'
import MenuItemsComponent from './MenuItemsComponent.js'
import InfoRounded from '@material-ui/icons/InfoRounded'
import { regionSvg, countrySvg, locationSvg, removeSvg } from './svgs.js'
import {
  IUIfontSizesm,
  IUIfontWeight400
} from '../../theming/variables/js/_typography.variables.js'
import {
  IUIblack500,
  IUIblack300,
  IUIblack700
} from '../../theming/variables/js/_colors.variables.js'
import { v4 as uuid } from 'uuid'

const CustomMenuList = (props, validation, setShowMenu) => {
  const [value, setValue] = useState(null)
  const scrollableDiv = useRef(null)
  useEffect(() => {
    if (
      props?.getValue &&
      props.getValue().length &&
      props.getValue()[0].value !== value
    ) {
      setValue(props.getValue()[0].value)
    }
  }, [props])

  useEffect(() => {
    const el = document.getElementById(
      `async-select-menu-item-${props.selectProps.id}-seleted-value-${value}`
    )
    if (el) {
      scrollableDiv.current.scrollTop = el.offsetTop
    }
  }, [value])

  if (props.options.length) {
    return (
      <div>
        <div
          className={clsx(
            classes.menuBox,
            'iui__scrollbar iui__borderRadius-6'
          )}
          onScroll={props.selectProps.handleScrolledToBottom}
          ref={scrollableDiv}
        >
          {props?.options?.length
            ? props?.options?.map((option, _i) => {
                const selectedValues = props.getValue()
                return (
                  <Box className={classes.mainListClass} key={uuid()}>
                    <FormLabel
                      className={clsx(
                        classes.categoryLabelMenu,
                        'iui__font--uppercase iui__font--para-small iui__py-0 iui__my-5 iui__d-block'
                      )}
                    >
                      {option.label.split('#')[1] === 'country'
                        ? 'Countries'
                        : option.label.split('#')[1] === 'city'
                        ? 'Cities'
                        : option.label.split('#')[1] === 'region'
                        ? 'Continents'
                        : ''}
                    </FormLabel>
                    {option.options?.length
                      ? option.options.map((catOption, catIndex) => {
                          if (
                            selectedValues.find((value) => {
                              return value.value === catOption.value
                            })
                          ) {
                            return null
                          }
                          let iconForLabel = null
                          if (option.label.split('#')[1] === 'country') {
                            iconForLabel = countrySvg(false)
                          } else if (option.label.split('#')[1] === 'city') {
                            iconForLabel = locationSvg(false)
                          } else if (option.label.split('#')[1] === 'region') {
                            iconForLabel = regionSvg(false)
                          }
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <MenuItemsComponent
                              key={uuid()}
                              menuData={{
                                index: catIndex,
                                categoryValue: catOption.value,
                                selectPropsId: props.selectProps.id,
                                label: catOption.label,
                                classForMenuItem: clsx(classes.popOverText),
                                selectOption: props.selectOption,
                                categoryData: catOption,
                                subLabelStateValue: catOption.state_name
                                  ? catOption.state_name
                                  : '',
                                subLabelValue: catOption.country_name
                                  ? catOption.country_name
                                  : '',
                                iconForLabel: iconForLabel
                              }}
                            />
                          )
                        })
                      : null}
                  </Box>
                )
              })
            : null}
        </div>
      </div>
    )
  } else if (validation?.searchText.length > 2 && props.selectProps.isLoading) {
    return (
      <div>
        <div
          className={clsx(
            classes.menuBox,
            'iui__scrollbar iui__borderRadius-6'
          )}
          onScroll={props.selectProps.handleScrolledToBottom}
          ref={scrollableDiv}
        >
          <MenuItem className={clsx(classes.dropdownLoader)}>
            <Typography>Loading...</Typography>
          </MenuItem>
        </div>
      </div>
    )
  } else if (
    validation?.searchText.length > 2 &&
    !props.selectProps.isLoading &&
    !props.options.length
  ) {
    return (
      <div>
        <div
          className={clsx(
            classes.menuBox,
            'iui__scrollbar iui__borderRadius-6'
          )}
          onScroll={props.selectProps.handleScrolledToBottom}
          ref={scrollableDiv}
        >
          <MenuItem className={clsx(classes.dropdownLoader)}>
            <Typography>Options not available</Typography>
          </MenuItem>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export const IUIAsyncMultiSelect = ({
  additional = {
    page: 0,
    tabelName: '',
    product: '',
    placeholder: '',
    userId: 0
  },
  label = null,
  secondaryLabel = null,
  description = null,
  required = false,
  value = [],
  name,
  customSelectHandler = () => {},
  inputType = false,
  loadOption = () => {},
  isError = false,
  errorMsg = '',
  validation = null,
  readOnly = false,
  placeHolderCheck = false,
  hasInfoIcon = false,
  tooltipText = '',
  id = '',
  isMulti = false, // Multi select Option
  disableText, // on hovering of disabled ones, this text will show on tooltip
  isMove = false,
  clearCache = 0,
  classname = ''
}) => {
  const [localValue, onChange] = useState(value) // need for onchange async select , need to pass setstate
  const [validationObj, setValidationObj] = useState(validation)
  const [showMenu, setShowMenu] = useState(false)

  const handleOnChange = (selectedOption, list) => {
    onChange(selectedOption, list)
    customSelectHandler(name, selectedOption, list)
  }

  useEffect(() => {
    setValidationObj(validation)
  }, [validation])

  useEffect(() => {
    onChange(value)
  }, [value])

  const MenuList = wrapMenuList((props) =>
    CustomMenuList(props, validationObj, setShowMenu)
  )

  const DropdownIndicator = () => {
    return null
  }

  const MultiValueContainer = (props) => {
    return (
      <Box className={clsx(classes.multiValueMainContainer)}>
        {props?.data?.type === 'country' ? (
          <Box className={clsx(classes.multiValueIcons)}>
            {countrySvg(false)}
          </Box>
        ) : null}{' '}
        {props?.data?.type === 'city' ? (
          <Box className={clsx(classes.multiValueIcons)}>
            {locationSvg(false)}
          </Box>
        ) : null}
        {props?.data?.type === 'region' ? (
          <Box className={clsx(classes.multiValueIcons)}>
            {regionSvg(false)}
          </Box>
        ) : null}
        <components.MultiValueContainer {...props} />
      </Box>
    )
  }
  const MultiValueRemove = (props) => {
    return (
      <components.MultiValueRemove {...props}>
        {removeSvg()}
      </components.MultiValueRemove>
    )
  }

  return (
    <div className={(clsx(readOnly ? classes.readOnly : ''), classname)}>
      <IUILabel
        label={label}
        secondaryLabel={secondaryLabel}
        description={description}
        required={required}
        hasInfoIcon={hasInfoIcon}
        tooltipText={tooltipText}
      />
      <Tooltip
        placement='top-start'
        disableHoverListener={!readOnly}
        disableFocusListener={!readOnly}
        disableTouchListener={!readOnly}
        title={disableText}
      >
        <div className={isMove ? classes.diableShow : classes.diableShowHide}>
          <AsyncPaginate
            id={id}
            placeholder={additional.placeholder}
            value={localValue}
            loadOptions={loadOption}
            debounceTimeout={500}
            additional={additional}
            className={clsx(
              classes.selectContainer,
              {
                [classes.inputContainer]: inputType
              },
              { [classes.errorBorderCls]: isError },
              { [classes.placeholderColor]: placeHolderCheck },
              { [classes.multiContainer]: isMulti }
            )}
            classNamePrefix='iui'
            name={name}
            onChange={handleOnChange}
            isDisabled={readOnly}
            isMulti={isMulti}
            styles={{
              menu: (base) => ({
                ...base,
                boxShadow: 'none',
                marginTop: '4px'
              }),
              multiValue: (base) => ({
                ...base,
                background: 'none',
                fontSize: IUIfontSizesm,
                fontWeight: IUIfontWeight400,
                color: IUIblack700
              }),
              multiValueLabel: (base) => ({
                ...base,
                fontSize: 'inherit',
                paddingLeft: 0
              }),
              control: (base, state) => ({
                ...base,
                maxHeight: '120px',
                overflowY: 'auto',
                borderColor: state.isSelected ? IUIblack500 : IUIblack300,
                boxShadow: state.isFocused
                  ? state.isSelected
                    ? 'none'
                    : '0px 0px 0px 4px #ffedd5'
                  : 'none',
                '&:hover': {
                  borderColor: IUIblack500
                }
              }),
              indicatorsContainer: (base) => ({
                ...base,
                display: 'none'
              }),
              multiValueRemove: (base) => ({
                ...base,
                padding: '0 0 0 10px',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'transparent'
                }
              })
            }}
            components={{
              MultiValueContainer,
              MultiValueRemove,
              DropdownIndicator,
              MenuList
            }}
            reduceOptions={reduceGroupedOptions}
            cacheUniqs={[clearCache]}
            menuPlacement='auto'
            isClearable={false}
            openMenuOnClick={showMenu}
            // menuIsOpen //! for debugging purpose
          />
        </div>
      </Tooltip>
      {validationObj.errorMsg !== '' ? (
        <Typography
          variant='body2'
          component='span'
          className={classes.errorTextCls}
        >
          <InfoRounded style={{ fontSize: '16px' }} />
          {validationObj.errorMsg}
        </Typography>
      ) : null}
    </div>
  )
}
