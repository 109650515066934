import React from 'react'
import { makeStyles, Tooltip } from '@material-ui/core'
import {
  IUIblack700,
  IUIwhite
} from '../../theming/variables/js/_colors.variables'
import {
  IUIfontSizesm,
  IUIfontWeight400
} from '../../theming/variables/js/_typography.variables'
export const IUITooltip = (props) => {
  const {
    tooltipText = '',
    arrow = true,
    placement = 'top-end',
    children,
    toolStyle = {},
    clickActive = false,
    open = false
  } = props

  const useToolStyle = makeStyles(() => ({
    arrow: {
      color: 'white'
    },
    tooltip: {
      backgroundColor: 'white',
      background: IUIwhite,
      color: IUIblack700,
      boxShadow: `0px 4px 8px rgba(17, 24, 39, 0.15), 0px 1px 2px rgba(17, 24, 39, 0.1)`,
      padding: 16,
      fontSize: IUIfontSizesm,
      borderRadius: 6,
      fontWeight: IUIfontWeight400,
      ...toolStyle
    }
  }))
  const classes = useToolStyle()

  return (
    <React.Fragment>
      {!clickActive ? (
        <Tooltip
          title={tooltipText}
          arrow={arrow}
          interactive
          placement={placement}
          classes={classes}
        >
          {children}
        </Tooltip>
      ) : (
        <Tooltip
          title={tooltipText}
          arrow={arrow}
          interactive
          placement={placement}
          classes={classes}
          open={open}
          PopperProps={{
            disablePortal: true
          }}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          {children}
        </Tooltip>
      )}
    </React.Fragment>
  )
}
