import React from 'react'
import { Switch } from '@material-ui/core'
import classes from './IUISwitch.module.scss'

export const IUISwitch = (props) => {
  const {
    id = '',
    name = '',
    isChecked = true,
    isRequired = false,
    isDisabled = false,
    idDisabledRipple = true,
    value = 'on',
    onToggleHandler = () => {},
    style = {},
    className = '',
    smallSize = false,
    ref = null
  } = props
  return (
    <Switch
      checked={isChecked}
      required={isRequired}
      disabled={isDisabled}
      disableRipple={idDisabledRipple}
      className={`${classes.customSwitch} ${
        isDisabled ? 'disabled' : 'enabled'
      } ${className} ${smallSize ? 'smallSize' : ''}`}
      value={value}
      id={id}
      onChange={onToggleHandler}
      name={`${name}-checked`}
      style={{ ...style }}
      inputRef={ref}
    />
  )
}
