import React from 'react'

export const Export = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      fill='none'
      viewBox='0 0 11 12'
    >
      <path
        stroke='#6B7280'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M4 1.501h6m0 0v6m0-6l-9 9'
      />
    </svg>
  )
}
