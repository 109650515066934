import { Select, MenuItem, Typography, FormControl } from '@material-ui/core'
import { ExpandMoreIcon } from '../../utils/icons/ExpandMoreIcon'
import React from 'react'
import clsx from 'clsx'
import classes from './IUISelect.module.scss'
import { IUILabel } from '../IUILabel/index.js'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: 300
  }
}))

export const IUICustomSelect = ({
  label = '',
  description = '',
  required = false,
  list = [],
  valueKey = '',
  value = '',
  fullWidth = false,
  onValueChange = () => {}, // param: { _name, _value, _isError, _errorMsg, _obj, _event }
  menuItemTitle = '',
  hasInfoIcon = false,
  headerType = false,
  name = '',
  isError = false,
  errorMsg = '',
  readOnly = false,
  tooltipText = '',
  id = '',
  disableText = '',
  labelKey = '',
  className = '',
  style = {}
}) => {
  const handleChange = (event) => {
    onValueChange({
      _name: event.target.name,
      _value: event.target.value
    })
  }
  const dropdownClasses = useStyles()

  return (
    <React.Fragment>
      <IUILabel
        label={label}
        description={description}
        required={required}
        hasInfoIcon={hasInfoIcon}
        headerType={headerType}
        tooltipText={tooltipText}
      />
      <Tooltip
        placement='top-start'
        disableHoverListener={!readOnly}
        disableFocusListener={!readOnly}
        disableTouchListener={!readOnly}
        title={disableText}
      >
        <FormControl disabled={readOnly} fullWidth>
          <Select
            id={id}
            displayEmpty
            name={name}
            value={value}
            style={{ ...style }}
            onChange={handleChange}
            IconComponent={(_props) => (
              <div className={classes.pr_icon} {..._props}>
                <ExpandMoreIcon width='10' height='10' />
              </div>
            )}
            error={isError}
            className={clsx(
              classes.customSelect,
              classes.customSelectSelector,
              classes.marginVal,
              className,
              classes.customIcon,
              { [classes.menuTitleColor]: value === '' },
              { [classes.readOnly]: readOnly }
            )}
            variant='outlined'
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              getContentAnchorEl: null,
              classes: {
                paper: dropdownClasses.menuPaper
              }
            }}
            fullWidth={fullWidth}
          >
            {menuItemTitle && (
              <MenuItem
                value=''
                className={clsx(classes.backgroundColor, classes.popOverText)}
              >
                {menuItemTitle}
              </MenuItem>
            )}

            {list.map((_c, _i) => {
              return (
                <MenuItem
                  key={_i}
                  id={_c.id}
                  value={_c[valueKey]}
                  className={clsx(classes.backgroundColor, classes.popOverText)}
                >
                  <Typography variant='inherit'>
                    {labelKey ? _c[labelKey] : _c[valueKey]}
                  </Typography>{' '}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Tooltip>

      {isError && (
        <Typography
          variant='body2'
          component='div'
          className={classes.errorTextCls}
        >
          {errorMsg}
        </Typography>
      )}
    </React.Fragment>
  )
}
