import { Accordion } from '@material-ui/core'
import React, { Fragment } from 'react'
import clsx from 'clsx'
import classes from './IUIAccordion.module.scss'

export const IUIAccordion = (props) => {
  const {
    expanded = false,
    onChange = () => {},
    className = '',
    style = {}
  } = props

  return (
    <Fragment>
      <Accordion
        expanded={expanded}
        onChange={onChange}
        className={clsx(className,classes.iuiAccordion)}
        style={{ ...style }}
      >
        {props.children}
      </Accordion>
    </Fragment>
  )
}
