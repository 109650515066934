import { Typography, Box } from '@material-ui/core'
import React from 'react'
import styles from './IUIListBar.module.scss'
import clsx from 'clsx'
import { Lock } from '../../icons/svg/LockIcon'
import { v4 as uuid } from 'uuid'

export const IUIListBar = ({
  listBarWrapperClass = null,
  primary = '',
  secondary = [],
  actions = null,
  outline = false,
  primaryComponent = null,
  hasDivider = true,
  isLocked = false
}) => {
  return (
    <Box
      className={clsx(
        styles.listWrapper,
        { [styles.outline]: outline },
        { [styles.divider]: hasDivider },
        listBarWrapperClass && listBarWrapperClass
      )}
    >
      {(primary || actions) && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: { xs: 'column', sm: 'row' }
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Typography className={styles.primaryText}>{primary}</Typography>
            {isLocked && <Lock fillColor='#6B7280' />}
          </Box>
          <Box>{actions}</Box>
        </Box>
      )}
      {secondary &&
        secondary.map((_c, _i) => {
          return (
            <Box
              width='100%'
              className='iui__d-flex iui__align-items-baseline iui__justify-content-spaceBetween '
              key={uuid()}
            >
              <Typography variant='caption' className={clsx(_c.customClass)}>
                {_c.label}
              </Typography>
              {_c.action && <Box>{_c.action}</Box>}
            </Box>
          )
        })}
      {primaryComponent && primaryComponent}
    </Box>
  )
}
