import { createTheme } from '@material-ui/core'
import {
  IUIblack500,
  IUIblack700,
  IUIblack900,
  IUIthemeColor
} from './variables/js/_colors.variables'

const __globals = createTheme({
  palette: {
    primary: {
      main: IUIthemeColor
    },
    secondary: {
      light: IUIblack500,
      main: IUIblack700,
      dark: IUIblack900
    }
  },
  typography: {
    fontFamily: ["'Inter'", 'sans-serif'].join(',')
  }
})
export default __globals
