import {
  OutlinedInput,
  Typography,
  IconButton,
  InputAdornment
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { IUILabel } from '../IUILabel'
import { IUIBox } from '../IUIBox'
import classes from './IUIPassword.module.scss'
import { checkEmptyObject } from '../../utils/_helpers'
import { PasswordEyeIconClose, PasswordEyeIconOpen } from '../../icons/svg'
import { Error as ErrorIcon } from '../../icons/svg/ErrorIcon'

// import PropTypes from 'prop-types'

export const IUIPassword = (props) => {
  const {
    label = null,
    secondaryLabel = null,
    labelDescription = null,
    labelInfoIcon = false,
    labelInfoText = '',
    name = '',
    id = '',
    value = '',
    required = false,
    placeholder = null,
    customInputClass = '',
    fullWidth = false,
    onValueChange = () => {}, // param: { _name, _value, _isError, _errorMsg, _obj, _event }
    className = '',
    validation = {},
    style = {},
    isError = false,
    errorMsg = ''
  } = props

  const [showPassword, setShowPassword] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const handleClickShowPassword = () => {
    setShowPassword((showPassword) => !showPassword)
  }

  const inputOnChange = (e) => {
    const _value = validation.isNumber ? Number(e.target.value) : e.target.value
    if (String(_value).trim() === '') {
      if (typeof onValueChange === 'function') {
        const param = {
          _name: name,
          _value: _value,
          _obj: null,
          _event: e
        }
        onValueChange(param)
      }
      setInputValue('')
    } else {
      if (!checkEmptyObject(validation) && validation.regexOnChange) {
        if (!validation.regexOnChange.test(_value)) {
          return false
        }
        if (
          validation.maxLength &&
          String(_value).length > validation.maxLength
        ) {
          return false
        }
      }

      if (typeof onValueChange === 'function') {
        const param = {
          _name: name,
          _value: _value,
          _obj: null,
          _event: e
        }
        onValueChange(param)
      }
      setInputValue(_value)
    }
  }
  const inputFocusOut = (e) => {
    let _isError = false
    let _errorMsg = ''
    const _value = validation.isNumber ? Number(e.target.value) : e.target.value
    if (required) {
      if (_value.trim() === '') {
        _isError = true
        _errorMsg = 'Password cannot be empty'
      } else {
        if (!checkEmptyObject(validation) && validation.regexOnBlur) {
          if (
            typeof validation.regexOnBlur === 'object' &&
            validation.regexOnBlur.length
          ) {
            for (let i = 0; i < validation.regexOnBlur.length; i++) {
              if (!validation.regexOnBlur[i].regex.test(_value)) {
                _isError = true
                _errorMsg = validation.regexOnBlur[i].message
                break
              }
            }
          } else {
            if (!validation.regexOnBlur.test(_value)) {
              _isError = true
              _errorMsg = 'Invalid password'
            }
          }
        }
      }
    } else {
      if (_value.trim() !== '') {
        if (validation.regexOnBlur) {
          if (
            typeof validation.regexOnBlur === 'object' &&
            validation.regexOnBlur.length
          ) {
            for (let i = 0; i < validation.regexOnBlur.length; i++) {
              if (!validation.regexOnBlur[i].regex.test(_value)) {
                _isError = true
                _errorMsg = validation.regexOnBlur[i].message
                break
              }
            }
          } else {
            if (!validation.regexOnBlur.test(_value)) {
              _isError = true
              _errorMsg = 'Invalid password'
            }
          }
        }
      }
    }

    if (typeof onValueChange === 'function') {
      const param = {
        _name: name,
        _value: _value,
        _isError: _isError,
        _errorMsg: _errorMsg,
        _obj: null,
        _event: e
      }
      onValueChange(param)
    }
  }
  useEffect(() => {
    setInputValue(value)

    return () => {
      setInputValue('')
    }
  }, [value])

  return (
    <IUIBox>
      <IUILabel
        label={label}
        secondaryLabel={secondaryLabel}
        description={labelDescription}
        required={required}
        hasInfoIcon={labelInfoIcon}
        tooltipText={labelInfoText}
      />

      <IUIBox>
        <OutlinedInput
          id={id}
          value={inputValue}
          required={required}
          placeholder={placeholder}
          className={clsx(
            customInputClass,
            classes.inputStyle,
            classes.inputSelectorCls,
            classes.marginVal,
            className,
            { [classes.fullWidth]: fullWidth },
            { [classes.inputBorder]: !isError },
            { [classes.errorBorder]: isError }
          )}
          error={isError}
          type={showPassword ? 'text' : 'password'}
          onChange={inputOnChange}
          onBlur={inputFocusOut}
          // inputProps={{...inputProps}}
          name={name}
          style={{ ...style }}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                onClick={handleClickShowPassword}
                edge='end'
                className={clsx(classes.IconButtonEdgeEnd)}
              >
                {showPassword ? (
                  <PasswordEyeIconOpen />
                ) : (
                  <PasswordEyeIconClose />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </IUIBox>
      {isError && (
        <Typography
          variant='caption'
          className={clsx(
            'iui__font--para-normal normal iui__font--color-red600 iui__d-flex iui__align-items-center iui__mt-4',
            classes.error
          )}
        >
          <IUIBox
            component='span'
            className='iui__d-flex iui__align-items-center iui__mr-2 iui__mb-1'
          >
            <ErrorIcon />
          </IUIBox>
          {errorMsg}
        </Typography>
      )}
    </IUIBox>
  )
}
