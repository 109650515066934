import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import { Box, Divider, TableSortLabel, Typography } from '@material-ui/core'
import clsx from 'clsx'
import styles from './IUITable.module.scss'
import { IUISearch } from '../IUISearch'
import { FilterIcon } from '../../utils/icons/FilterIcon'
import { IUICheckBox } from '../IUICheckbox'
import Pagination from '@material-ui/lab/Pagination'
import { IUISkeleton } from '../IUISkeleton'

const skeltondata = [
  {
    variant: 'rect',
    height: 100,
    style: { top: 20, bottom: 20 }
  },
  {
    variant: 'rect',
    height: 50,
    style: { bottom: 20 }
  },
  {
    variant: 'rect',
    height: 500,
    style: { bottom: 20 }
  }
]

export const IUITable = (props) => {
  const {
    hasFilter = false,
    filterComponent = '',
    searchPlaceholder = '',
    isCheckboxTable = true,
    columns = [],
    rows = [],
    rowsPerPage = 5,
    page = 1,
    handleChangePage = () => {},
    handleChangeRowsPerPage = () => {},
    count = 0,
    handleSelectAllClick = () => {},
    selected = [],
    handleSingleCheckBoxClick = () => {},
    orderBy,
    order = 'asc',
    onRequestSort = () => {},
    onSearchHandler = () => {},
    searchHide = true,
    hideFilter = true,
    handleRowClick = () => {},
    isRowClickable = () => {},
    disableHeaderCheckbox = false,
    disableRowCheckbox = [],
    searchValue = '',
    searchBoxWidth = '',
    hidePagination = false,
    hideTopPagination = true,
    isDataFetching = false
  } = props

  const useStyles = makeStyles({
    root: {
      width: '100%',
      boxShadow: 'none'
    }
  })

  const classes = useStyles()
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const createSearchHandler = (event) => {
    onSearchHandler(event.target.value)
  }

  const [currentPage, setCurrentPage] = useState(page)
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(rowsPerPage)

  useEffect(() => {
    if (currentPage !== page || currentRowsPerPage !== rowsPerPage) {
      setCurrentPage(page)
      setCurrentRowsPerPage(rowsPerPage)
    }
  }, [page, rowsPerPage])

  const TablePaginationActions = () => {
    return (
      <Box
        sx={{
          flexShrink: 0,
          ml: 2.5
        }}
      >
        <Pagination
          count={Math.ceil(count / rowsPerPage)}
          page={page}
          variant='outlined'
          shape='rounded'
          onChange={handleChangePage}
        />
      </Box>
    )
  }

  return (
    <Paper className={clsx(classes.root, styles.tableContainer)}>
      {hasFilter && (
        <React.Fragment>
          <Box
            component='div'
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            className={clsx(
              styles.filterBar,
              hideFilter && searchHide && styles.left
            )}
          >
            {!hideFilter && (
              <Box
                component='div'
                display='flex'
                className={clsx(styles.gap, styles.large)}
              >
                <Box
                  component='div'
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                  className={clsx(styles.gap, styles.small)}
                >
                  <FilterIcon width='17' height='17' />
                  <Typography
                    variant='body1'
                    className={clsx(styles.description, styles.semiBoldtext)}
                  >
                    Filters
                  </Typography>
                </Box>
                {filterComponent}
              </Box>
            )}

            {!searchHide && (
              <Box component='div' style={{ width: searchBoxWidth }}>
                <IUISearch
                  value={searchValue}
                  placeholder={searchPlaceholder}
                  onChangeHandler={(e) => createSearchHandler(e)}
                />
              </Box>
            )}
            {hidePagination ||
            hideTopPagination ||
            rowsPerPage < 10 ||
            count < 10 ||
            rows.length < 10 ? null : (
              <Box className={clsx(styles.topPagination)}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  component='div'
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={+page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  className={classes.pageRoot}
                  ActionsComponent={TablePaginationActions}
                />
              </Box>
            )}
          </Box>
          <Divider className={styles.divider} />
        </React.Fragment>
      )}
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          aria-label='sticky table'
          style={{ height: 'auto !important' }}
        >
          <TableHead>
            <TableRow>
              {isCheckboxTable && (
                <TableCell padding='checkbox' style={{ minWidth: '12px' }}>
                  <IUICheckBox
                    checked={
                      rows.length > 0 &&
                      selected &&
                      selected[page] &&
                      selected[page].length ===
                        rows.length - disableRowCheckbox.length
                    }
                    disabled={disableHeaderCheckbox}
                    handleCheckBoxHandler={(event) =>
                      handleSelectAllClick(event, page)
                    }
                  />
                </TableCell>
              )}

              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align={column.align}
                  style={{
                    ...column.style,
                    minWidth: column.minWidth,
                    width: column.width,
                    maxWidth: column.maxWidth
                  }}
                  className={clsx(column.style)}
                >
                  {column.sortable ? (
                    <TableSortLabel
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={createSortHandler(column.id)}
                    >
                      <Typography
                        variant='body1'
                        className={styles.columnHeader}
                      >
                        {column.label}
                      </Typography>
                      {/* {orderBy === column.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </span>
                      ) : null} */}
                    </TableSortLabel>
                  ) : (
                    <Typography variant='body1' className={styles.columnHeader}>
                      {column.label}
                    </Typography>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* className={clsx({ [styles.bodyHeight]: isDataFetching })} */}

            {!isDataFetching ? (
              count === 0 ? (
                <TableRow>
                  <TableCell align='center' colSpan={columns?.length}>
                    <Box className={styles.emptyData}>No data available</Box>
                  </TableCell>
                </TableRow>
              ) : (
                rows.map((row, index) => {
                  // const isItemSelected = isSelected(row.id.toString())
                  return (
                    <TableRow
                      hover
                      role='checkbox'
                      tabIndex={-1}
                      key={index}
                      onClick={(e) => handleRowClick(e, row)}
                      style={{
                        cursor: isRowClickable(row) ? 'pointer' : 'default'
                      }}
                    >
                      {isCheckboxTable && (
                        <TableCell
                          padding='checkbox'
                          style={{ minWidth: '12px' }}
                        >
                          <IUICheckBox
                            handleCheckBoxHandler={(e) =>
                              handleSingleCheckBoxClick(
                                e,
                                row.id.toString(),
                                page,
                                row
                              )
                            }
                            checked={
                              selected && selected[page]
                                ? selected[page].indexOf(row.id.toString()) !==
                                  -1
                                : false
                            }
                            id={row.id.toString()}
                            disabled={
                              disableRowCheckbox
                                ? disableRowCheckbox.indexOf(
                                    row.id.toString()
                                  ) !== -1
                                : false
                            }
                          />
                        </TableCell>
                      )}
                      {columns.map((column, colIndex) => (
                        <TableCell
                          key={colIndex}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            width: column.width,
                            maxWidth: column.minWidth
                          }}
                        >
                          {column.cell(row, index)}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })
              )
            ) : (
              <TableRow>
                <TableCell align='center' colSpan={columns?.length}>
                  <IUISkeleton data={skeltondata} />
                </TableCell>
              </TableRow>
            )}
            {/* {isDataFetching && (
              <Grid container className={styles.progressContainer}>
                <Grid item lg='12' md='12' xl='12'>
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    position='relative'
                  >
                    <Typography>Processing...</Typography>
                  </Box>
                </Grid>
              </Grid>
            )} */}
            {/* {count === 0 && !isDataFetching && (
              <Grid container className={styles.progressContainer}>
                <Grid item lg='12' md='12' xl='12'>
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    position='relative'
                  >
                    <Typography>No reuslts found</Typography>
                  </Box>
                </Grid>
              </Grid>
            )} */}
          </TableBody>
        </Table>
      </TableContainer>
      {hidePagination || isDataFetching ? null : (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component='div'
          count={count}
          rowsPerPage={rowsPerPage}
          page={+page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={classes.pageRoot}
          ActionsComponent={TablePaginationActions}
        />
      )}
    </Paper>
  )
}
