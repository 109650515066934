import { Step, StepContent, StepLabel, Stepper } from '@material-ui/core'
import React from 'react'
import clsx from 'clsx'

export const IUIStepper = (props) => {
  const {
    activeStep = 0,
    nonLinear = true,
    className = '',
    steps = ['Step1', 'Step2'],
    StepContents = '',
    CustomStepIcon = () => {}, // custom icons can be used here , jsx format
    width = false, // width of the stepper,
    style = {},
    orientation = 'horizontal',
    StepContentClass = ''
  } = props
  return (
    <Stepper
      activeStep={activeStep}
      nonLinear={nonLinear}
      className={clsx(className)}
      style={{ width: width && width, ...style }}
    >
      {StepContents && StepContents.length !== 0 ? (
        <Stepper activeStep={activeStep} orientation={orientation}>
          {StepContents.map((item, _i) => {
            return (
              <Step expanded key={_i}>
                <StepLabel icon={CustomStepIcon(_i)}>
                  {item.label ? item.label : ''}
                </StepLabel>
                {item.content && (
                  <StepContent className={StepContentClass}>
                    {item.content}
                  </StepContent>
                )}
              </Step>
            )
          })}
        </Stepper>
      ) : (
        steps.map((label, _i) => (
          <Step key={label}>
            <StepLabel icon={CustomStepIcon(_i)}>{label}</StepLabel>
          </Step>
        ))
      )}
    </Stepper>
  )
}
