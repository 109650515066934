// import React from 'react'

import { IUIInput } from './IUIInput'
import { IUISelect } from './IUISelect'
import { IUIAsyncSelect } from './IUIAsyncSelect'
import { IUIRadio } from './IUIRadio'
import { IUIButton } from './IUIButton'
import { IUIBreadCrumb } from './IUIBreadcrumb'
import { IUIPhone } from './IUIPhone'
import { IUICheckboxButton } from './IUICheckboxButton'
import { IUIDatePicker } from './IUIDatePicker'
import { IUIModal } from './IUIModal'
import { IUIPageHeader } from './IUIPageHeader'
import { IUISwitch } from './IUISwitch'
import { IUIAlert } from './IUIAlert'
import { IUICard } from './IUICard'
import { IUIListBar } from './IUIListBar'
import { IUIRadioGroup } from './IUIRadioGroup'
import { IUILayout } from './IUILayout'
import { IUIMultiInput } from './IUIMultiInput'
import { IUIPaymentCard } from './IUIPaymentCard'
import { IUIAsyncMultiSelect } from './IUIAsyncMultiSelect'
import { IUIPassword } from './IUIPassword'

// export { default as IUIAccordion } from './IUIAccordion'
// export * from './IUIAccordion'

export const Components = {
  IUIInput,
  IUISelect,
  IUIRadio,
  IUIButton,
  IUIBreadCrumb,
  IUIPhone,
  IUICheckboxButton,
  IUIDatePicker,
  IUIModal,
  IUIAsyncSelect,
  IUIPageHeader,
  IUISwitch,
  IUIAlert,
  IUICard,
  IUIListBar,
  IUIRadioGroup,
  IUILayout,
  IUIMultiInput,
  IUIPaymentCard,
  IUIAsyncMultiSelect,
  IUIPassword
}
